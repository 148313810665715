import {FC, useCallback, createRef, useRef, useState, useEffect} from "react";
import {BankAccount} from "../../types/bank";

import {
    Metaplex,
    Nft as MetaplexNft,
    Metadata,
    JsonMetadata,
    walletAdapterIdentity,
    findMetadataPda,
    findMasterEditionV2Pda
} from "@metaplex-foundation/js";
import {PublicKey, sendAndConfirmRawTransaction, SystemProgram, Transaction} from "@solana/web3.js";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {AnchorProvider, BN, Program} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";
// import {fetchTokenBalance} from "../../utils/fetch";
// import AttachCollectionNfts from "./AttachCollectionNfts";
import "./CollectionAccountItem.css";
import {
    getAssociatedTokenAddress,
    closeAccount,
    createTransferInstruction,
    createAssociatedTokenAccountInstruction,
    createCloseAccountInstruction,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import {REWARD_TOKEN_MINT, REWARD_TOKEN_DECIMAL} from "../../utils/constant";
import web3 from "@solana/web3.js";
import {fetchTokenBalance} from "../../utils/fetch";

interface Props {
    bank: BankAccount;
}

const BankAccountItem: FC<Props> = (props) => {
    const [isBankAccount, setIsBankAccount] = useState<boolean>(false);
    const [bankTokenAccount, setBankTokenAccount] = useState<PublicKey>();
    const [tokenBalance, setTokenBalance] = useState<string>();
    const [panelVisibility, setPanelVisibility] = useState<boolean>(false);
    const authorityWithdrawField = createRef<HTMLInputElement>();
    const refillAmountField = createRef<HTMLInputElement>();

    const {connection} = useConnection();
    const wallet = useWallet();

    const AddBankAcc = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // setIsLoading(true);

        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [bankAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("flwr_bank")],
                program.programId
            );

            const tx = new Transaction().add(
                program.instruction.initializeBank(bump, {
                    accounts: {
                        user: userAddress,
                        pdaBank: bankAccount,
                        systemProgram: SystemProgram.programId,
                    }
                })
            );

            await provider.sendAndConfirm(tx);
        } catch (err) {
            // console.error({err});
        } finally {
            // setIsLoading(false);
        }
    }, [wallet, connection]);

    const removeBankAcc = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // setIsLoading(true);

        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [bankAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("flwr_bank")],
                program.programId
            );

            const tx = new Transaction().add(
                program.instruction.removeBank({
                    accounts: {
                        user: userAddress,
                        pdaBank: bankAccount,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx);
        } catch (err) {
            // console.error({err});
        } finally {
            // setIsLoading(false);
        }
    }, [wallet, connection]);

    const refillBank = useCallback(async () => {
        if (!refillAmountField.current) return;

        const userTokenAddress = await getAssociatedTokenAddress(
            REWARD_TOKEN_MINT,
            wallet.publicKey,
            true
        );

        const tokenAccount = await getAssociatedTokenAddress(
            REWARD_TOKEN_MINT,
            props.bank.publicKey,
            true
        )

        const transaction = new Transaction().add(
            createTransferInstruction(
                userTokenAddress,
                tokenAccount,
                wallet.publicKey,
                parseInt(refillAmountField.current.value) * REWARD_TOKEN_DECIMAL
            )
        );

        try {
            const latestBlockHash = await connection.getLatestBlockhash();
            transaction.recentBlockhash = latestBlockHash.blockhash;
            transaction.feePayer = wallet.publicKey;
            const signedTransaction = await wallet.signTransaction(transaction);

            await connection.sendRawTransaction(signedTransaction.serialize())
        } catch (err) {
            // console.error({err})
        }

    }, [connection, wallet, refillAmountField]);

    useEffect(() => {
        const main = async () => {
            if (props.bank) {
                const bankAssociatedTokenAddress = await getAssociatedTokenAddress(
                    REWARD_TOKEN_MINT,
                    props.bank.publicKey,
                    true
                )

                const bankTokenAcc = await connection.getAccountInfo(bankAssociatedTokenAddress)

                setIsBankAccount(!!bankTokenAcc)
                setBankTokenAccount(bankAssociatedTokenAddress)
            }
        };
        main();
    }, [props.bank, connection, wallet]);

    useEffect(() => {
        const interval = setInterval(
            async () => {
                if (props.bank) {
                    const balance = await fetchTokenBalance(
                        connection,
                        props.bank.publicKey
                    );
                    setTokenBalance((Math.round(balance * 100) / 100).toString());
                }
            },
            tokenBalance ? 1000 : 500
        );
        return () => clearInterval(interval);
    }, [connection, props.bank, tokenBalance]);

    // const togglePanel = (isPanelVisible: boolean) => {
    //     setPanelVisibility(isPanelVisible);
    // };

    return (
        <>
            {props.bank &&
                <div
                    className="bank-account-item glass-effect"
                    style={panelVisibility ? {filter: "none", backdropFilter: "none"} : {}}
                >
                    <div className="bank-account-data">
                        <div className="bank-account-infos">
                            <h5 className="bank-title">Flower Bank</h5>
                            <div className="bank-info-section">
                                <p>Bank Account</p>
                                <a
                                    href={`https://explorer.solana.com/address/${props.bank?.publicKey.toString()}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.bank?.publicKey.toString()}
                                </a>
                            </div>

                            <div className="bank-info-section">
                                <p>Owner Account</p>
                                <a
                                    href={`https://explorer.solana.com/address/${props.bank?.account.authorityWithdraw.toString()}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.bank?.account.authorityWithdraw.toString()}
                                </a>
                            </div>
                        </div>

                        <span className="separation"/>

                        <div className="bank-refill-section">
                            <div>
                                <p>Token balance</p>
                                <b>{tokenBalance || 0} FLWR</b>
                            </div>
                            <div className="bank-option">
                                Refill amount
                                <input type="number" defaultValue={0} ref={refillAmountField}
                                       disabled={!isBankAccount}/>
                            </div>
                            <div className="bank-option">
                                <button type="button" onClick={refillBank} disabled={!isBankAccount}>
                                    Refill Bank
                                </button>
                            </div>
                        </div>

                        <span className="separation"/>

                        <div className="bank-option">
                            <button type="button" onClick={removeBankAcc}>
                                Remove Bank
                            </button>
                        </div>
                    </div>
                </div>}
            {!props.bank &&
                <div className="bank-option" style={{display: 'flex', flexDirection: 'column'}}>
                    <h4 style={{fontFamily: "Nighty", fontSize: "1.8rem"}}>
                        It looks like this wallet does not own a staking bank
                    </h4>
                    <button type="button" onClick={AddBankAcc}>
                        Add Bank
                    </button>
                </div>
            }
        </>
    );
};

export default BankAccountItem;
