import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {
    PublicKey,
    Transaction,
    SystemProgram,
    TransactionInstruction,
    sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import {AnchorProvider, Program, BN} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";
// import { FLOWER_ADDRESSES } from "../../utils/constant";
import {
    Metaplex,
    Metadata,
    walletAdapterIdentity,
    toMetaplexFileFromBrowser,
    findMetadataPda,
    findMasterEditionV2Pda,
} from "@metaplex-foundation/js";
import {
    createSetAndVerifyCollectionInstruction,
    createUnverifyCollectionInstruction,
} from "@metaplex-foundation/mpl-token-metadata";
// import { createSetAndVerifyCollectionInstruction } from "@metaplex-foundation/mpl-token-metadata";
import {nftStorage} from "@metaplex-foundation/js-plugin-nft-storage";
import {FC, useCallback, useRef, useState, ChangeEvent} from "react";
import "./CreateCollectionButton.css";

const NFT_STORAGE_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDI0ODIzRmVlMUUwM0MwYTBkZjk4Rjk2QUMwRjdmMjNkRjlhRmZCMGIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MzkxMzc3MDU1NiwibmFtZSI6IkZMV1IgLSBDb2xsZWN0aW9ucyJ9.B2NrfIttPFun1hJRuplS-sly6TvB0pl9062TNzXLfZI";

const InitCollectionButton: FC = () => {
    const createCollectionDiv = useRef<HTMLDivElement>();
    const collectionAddress = useRef<HTMLInputElement>();
    const NFTField = useRef<HTMLInputElement>();
    const rewardMultiplier = useRef<HTMLInputElement>();
    const {connection} = useConnection();
    const wallet = useWallet();
    const [nftCollection, setNftCollection] = useState<number>(0);
    const [imageUrl, setImageUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {publicKey, signAllTransactions} = useWallet();

    const attachNfts = useCallback(
        async (collectionMint) => {
            const metaplex = new Metaplex(connection);

            if (!NFTField.current.value) return;

            if (!publicKey || !signAllTransactions) return;

            const instructions: TransactionInstruction[] = [];

            const nftsFromInput = NFTField.current.value
                .split("\n")
                .map((address) => new PublicKey(address.trim()));

            const nftFetched = (await metaplex
                .nfts()
                .findAllByMintList({mints: nftsFromInput})) as Metadata[];

            for (const nft of nftFetched as Metadata[]) {
                if (!nft) {
                    continue;
                }
                if (
                    nft.collection?.address.toString() === collectionMint.toString() &&
                    nft.collection?.verified === true
                ) {
                    continue;
                }
                const accounts = {
                    metadata: findMetadataPda(nft.mintAddress),
                    collectionAuthority: publicKey,
                    payer: publicKey,
                    updateAuthority: publicKey,
                    collectionMint: collectionMint,
                    collection: findMetadataPda(collectionMint),
                    collectionMasterEditionAccount:
                        findMasterEditionV2Pda(collectionMint),
                };

                if (nft.collection) {
                    const unVerifyCollectionInstruction =
                        createUnverifyCollectionInstruction({
                            ...accounts,
                            collectionMint: nft.collection.address,
                            collection: findMetadataPda(nft.collection.address),
                            collectionMasterEditionAccount: findMasterEditionV2Pda(
                                nft.collection.address
                            ),
                        });
                    instructions.push(unVerifyCollectionInstruction);
                }

                const verifyCollectionInstruction =
                    createSetAndVerifyCollectionInstruction(accounts);

                instructions.push(verifyCollectionInstruction);
            }

            const transactionSize = 20;
            const transactionGroupSize = 5;
            const transactionGroups: Transaction[][] = [[new Transaction()]];
            for (const instruction of instructions) {
                let transactionGroup = transactionGroups[transactionGroups.length - 1];
                if (transactionGroup.length > transactionGroupSize) {
                    transactionGroup = [new Transaction()];
                    transactionGroups.push(transactionGroup);
                }
                let transaction = transactionGroup[transactionGroup.length - 1];
                if (transaction.instructions.length > transactionSize) {
                    transaction = new Transaction();
                    transactionGroup.push(transaction);
                }
                transaction.add(instruction);
            }

            setIsLoading(true);
            for (let i = 0; i < transactionGroups.length; i++) {
                const transactionGroup = transactionGroups[i];
                const blockHash = await connection.getLatestBlockhash();
                transactionGroup.forEach((tx) => {
                    tx.recentBlockhash = blockHash.blockhash;
                    tx.feePayer = publicKey!;
                });
                const signedTransactions = await signAllTransactions(transactionGroup);
                for (const signedTransaction of signedTransactions) {
                    try {
                        await sendAndConfirmRawTransaction(
                            connection,
                            signedTransaction.serialize()
                        )
                    } catch (error) {
                        // console.error(error);
                    }
                }
            }

            setIsLoading(false);
        },
        [connection, publicKey, NFTField, signAllTransactions]
    );

    const createCollectionState = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        setIsLoading(true);

        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });
            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const collAddr = collectionAddress.current.value
            const collectionId = new PublicKey(collAddr)

            await attachNfts(collectionId)

            const userAddress = wallet.publicKey as PublicKey;
            const [collectionAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("collection"), collectionId.toBuffer()],
                program.programId
            );

            const reward = parseFloat(rewardMultiplier.current.value);

            const tx = new Transaction().add(
                program.instruction.initializeCollection(new BN(bump), reward, {
                    accounts: {
                        user: userAddress,
                        pdaCollection: collectionAccount,
                        collectionId: collectionId,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx);
        } catch (err) {
            // console.error({ err });
        } finally {
            setIsLoading(false);
        }
    }, [wallet, connection, attachNfts]);

    const openCreateCollection = () => {
        createCollectionDiv.current.classList.add("visible");
    };

    const closeCreateCollection = () => {
        createCollectionDiv.current.classList.remove("visible");
    };

    const onSelectImage = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        setImageUrl(URL.createObjectURL(target.files[0]));
    };

    return (
        <div className="collection-create">
            <div className="create-collection-options" ref={createCollectionDiv}>
                <div className="glass-effect create-collection-panel">
                    <div className="create-collection-fields">
                        <h3>Link existing Collection</h3>
                        <p>
                            A collection is used to group multiple NFTs under a same category
                            and holds the staking parameters, like the reward amount
                        </p>
                        <div className="collection-input">
                            <label>Collection Address</label>
                            <input type="text" placeholder="name" ref={collectionAddress}/>
                        </div>
                        <div className="collection-input">
                            <label>NFTs</label>
                            <textarea placeholder="NFTs" ref={NFTField}/>
                        </div>
                        <div className="collection-input">
                            <label>Reward Multiplicator</label>
                            <input type="number" placeholder="0" ref={rewardMultiplier}/>
                        </div>
                    </div>
                    <div className="create-collection-confirm">
                        <button type="button" onClick={closeCreateCollection}>
                            Cancel
                        </button>
                        <button type="button" onClick={createCollectionState}>
                            Create Collection
                        </button>
                        {isLoading && <div className="lds-dual-ring"></div>}
                    </div>
                </div>
            </div>
            <button type="button" onClick={openCreateCollection}>
                Link Collection
            </button>
        </div>
    );
};

export default InitCollectionButton;
