import React from "react";
import { FC, useCallback, useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import group from "../../assets/icons/shovel.png";
import harvest from "../../assets/icons/harvest.png";
import select from "../../assets/icons/Select.svg";
import type { Wallet } from "@project-serum/anchor";
import Nft from "../../utils/nft";
import {
  recursiveStakeSelectedNfts,
  recursiveUnstakeSelectedNfts,
  recursiveClaimSelectedNfts
} from "../../utils/transaction";
import { fetchOwnedFlowers } from "../../utils/fetch";
import { MASTER_COLLLECTIONS } from "../../utils/constant";
import "./Actions.css";

interface Props {
  nfts: Nft[];
  nftTotal: Nft[];
  isStake: boolean;
  updateNfts: (nfts: Nft[]) => void;
  setLoading: (value: boolean) => void;
}

const Actions: FC<Props> = ({
  nfts,
  isStake,
  updateNfts,
  nftTotal,
  setLoading,
}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [collectionCount, setCollectionCount] = useState<[number, number, number]>([
    0, 0, 0
  ]);

  const selectAll = useCallback(() => {
    const select = !nfts[0].interactiveData.selected;
    nfts.slice(0, 200).forEach((nft) => nft.setSelected(select));
    updateNfts(nfts);
  }, [nfts, updateNfts]);

  const stake = useCallback(async () => {
    setLoading(true);
    try {
      await recursiveStakeSelectedNfts(
        connection,
        wallet as any as Wallet,
        nfts,
        (nft?: Nft) => {
          updateNfts(nft ? [nft] : []);
        }
      );
    } finally {
      updateNfts(await fetchOwnedFlowers(connection, wallet as unknown as Wallet));
      setLoading(false);
    }
  }, [connection, wallet, nfts, setLoading, updateNfts]);

  const unstake = useCallback(async () => {
    setLoading(true);
    try {
      await recursiveUnstakeSelectedNfts(
        connection,
        wallet as any as Wallet,
        nfts,
        (nft?: Nft) => {
          updateNfts(nft ? [nft] : []);
        }
      );
    } finally {
      updateNfts(await fetchOwnedFlowers(connection, wallet as unknown as Wallet));
      setLoading(false);
    }
  }, [connection, wallet, nfts, setLoading, updateNfts]);

  const claim = useCallback(async () => {
    setLoading(true);
    try {
      await recursiveClaimSelectedNfts(
        connection,
        wallet as any as Wallet,
        nfts,
        (nft?: Nft) => {
          updateNfts(nft ? [nft] : []);
        }
      );
    } finally {
      updateNfts(await fetchOwnedFlowers(connection, wallet as unknown as Wallet));
      setLoading(false);
    }
  }, [connection, wallet, nfts, setLoading, updateNfts]);

  useEffect(() => {
    const counts: Record<string, number> = { "OG": 0, "3D": 0, "GG": 0 };
    nfts.forEach((nft) => {
      for (const collection in MASTER_COLLLECTIONS) {
        if (nft.nftData.collectionAddress.toString() === MASTER_COLLLECTIONS[collection].toString()) {
          counts[collection]++;
        }
      }
    });
    setCollectionCount([counts["OG"], counts["3D"], counts["GG"]]);
  }, [nfts]);

  return (
    <div className="action-wrapper">
      <div className="spacer">
        {!isStake && (
          <div className="inventory-card">
            <div className="title">Inventory</div>
            <div className="value">
              {nfts.length}/{nftTotal.length}
            </div>
          </div>
        )}

        {isStake && (
          <div className="garden-card">
            <div className="title">Garden</div>
            <div className="value orange">OG : {collectionCount[0]}</div>
            <div className="value green">3D : {collectionCount[1]}</div>
            <div className="value purple">GG : {collectionCount[2]}</div>
          </div>
        )}
      </div>

      <div
        className={`btn-icon action-button ${nfts.every((nft) => nft.interactiveData.selected) ? "active" : ""
          }`}
        onClick={selectAll}
      >
        <img src={select} alt="select" />
      </div>
      <div className="label">Select All</div>

      <div
        className="btn-icon action-button"
        onClick={isStake ? unstake : stake}
      >
        <img
          src={group}
          style={{
            height: "70%",
            opacity: 0.8,
            transform: isStake ? "" : "rotate(180deg)",
          }}
          alt="groot"
        />
      </div>
      <div className="label">{!isStake ? "Stake" : "Unstake"}</div>
      {isStake && (
        <>
          <div className="btn-icon action-button" onClick={claim}>
            <img
              src={harvest}
              style={{
                height: "70%",
                opacity: 0.8,
                transform: isStake ? "" : "rotate(180deg)",
              }}
              alt="groot"
            />
          </div>
          <div className="label">Claim</div>
        </>
      )}
    </div>
  );
};

export default Actions;
