import {FC, useRef, useState} from "react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {
    PublicKey,
    Transaction,
    SystemProgram,
} from "@solana/web3.js";
import {AnchorProvider, Program, BN} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";
import "./AddWhitelistButton.css";

interface Props {
    handleUpdate: Function
}

const InitWhitelistButton: FC <Props> = (props) => {
    const addWhitelistDiv = useRef<HTMLDivElement>();
    const walletAddressDiv = useRef<HTMLInputElement>();
    const roleDiv = useRef<HTMLInputElement>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const {connection} = useConnection();
    const wallet = useWallet();

    const handleClick = async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!walletAddressDiv.current.value.trim() || !roleDiv.current.value.trim()) return

        const provider = new AnchorProvider(connection, wallet as any as Wallet, {
            preflightCommitment: "processed",
            commitment: "processed",
        });

        const program = new Program(
            stakeProgramIdl as Idl,
            new PublicKey(stakeProgramIdl.metadata.address),
            provider
        );

        await wallet.connect();

        const authority = new PublicKey(walletAddressDiv.current.value)
        const role = roleDiv.current.value

        const [whitelistAccount, bump] = await PublicKey.findProgramAddress(
            [Buffer.from("whitelist"), authority.toBuffer()],
            program.programId
        );

        const tx = new Transaction().add(
            program.instruction.initializeWhitelist(new BN(bump), authority, role, {
                accounts: {
                    user: wallet.publicKey as PublicKey,
                    authority,
                    pdaWhitelist: whitelistAccount,
                    systemProgram: SystemProgram.programId,
                }
            })
        )

        await provider.sendAndConfirm(tx);
        closeAddWhitelistModal();
        await props.handleUpdate();
    }

    const openAddWhitelistModal = () => {
        addWhitelistDiv.current.classList.add("visible");
    };

    const closeAddWhitelistModal = () => {
        addWhitelistDiv.current.classList.remove("visible");
        walletAddressDiv.current.value = ""
        roleDiv.current.value = ""
        setErrorMsg("")
    };

    return (
        <div className="whitelist-create">
            <div className="create-whitelist-options" ref={addWhitelistDiv}>
                <div className="glass-effect create-whitelist-panel">
                    <div className="create-whitelist-fields">
                        <h3>Add Whitelist Wallet</h3>
                        <div className="whitelist-input">
                            <label>Wallet Address</label>
                            <input type="text" placeholder="Wallet Address" ref={walletAddressDiv}/>
                        </div>
                        <div className="whitelist-input">
                            <label>Role</label>
                            <select name="role" ref={roleDiv}>
                                <option value={1}>Root</option>
                                <option value={2}>Admin</option>
                            </select>
                        </div>
                    </div>
                    {errorMsg && <p className="error-text">{errorMsg}</p>}
                    <div className="create-whitelist-confirm">
                        <button type="button" onClick={closeAddWhitelistModal}>Cancel</button>
                        <button type="button" onClick={handleClick}>Add</button>
                        {isLoading && <div className="lds-dual-ring"></div>}
                    </div>
                </div>
            </div>
            <button type="button" onClick={openAddWhitelistModal}>
                Add Whitelist Wallet
            </button>
        </div>
    );
};

export default InitWhitelistButton;
