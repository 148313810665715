import {FC, useCallback, useEffect, useState} from "react";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import type {Wallet} from "@project-serum/anchor";

import Header from "./stake/Header";
import AddSingleSwapButton from "./swap/AddSingleSwapButton";
import AddMultiSwapButton from "./swap/AddMultiSwapButton";
import SwapAccountList from "./swap/SwapAccountList";
import {fetchSwapStates, fetchWhitelistStates} from "../utils/fetch";
import {SwapAccount} from "../types/bank";
import "./SwapView.css";

const SwapView: FC = () => {
    const [swapAccount, setSwapAccount] = useState<SwapAccount[]>([]);
    const {connection} = useConnection();
    const wallet = useWallet();

    const [whitelistAccount, setWhitelistAccount] = useState([]);

    const getWhitelistAccounts = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;
        const whitelists = (await fetchWhitelistStates(connection, wallet as any as Wallet)).map(whitelist => whitelist.account.authority.toString())
        setWhitelistAccount(whitelists)
    }, [connection, wallet])

    const getSwapAccount = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;

        const swapStates: SwapAccount[] = await fetchSwapStates(connection, wallet as any as Wallet);

        setSwapAccount(swapStates);

    }, [connection, wallet]);

    async function handleUpdate() {
        await getSwapAccount();
    }

    useEffect(() => {
        try {
            getWhitelistAccounts();
            getSwapAccount();
        } catch {
        }
    }, [getWhitelistAccounts, getSwapAccount]);

    return (
        <>
            <Header title="Swap Area"/>

            {whitelistAccount.length > 0 && wallet.publicKey && whitelistAccount.includes(wallet.publicKey.toString()) &&
                <div className="swap-accounts">
                    <div>
                        <SwapAccountList swapStates={swapAccount} handleUpdate={handleUpdate}/>
                    </div>
                    <div style={{display: "flex", gap: "8px", justifyContent: "center"}}>
                        <AddSingleSwapButton handleUpdate={handleUpdate}/>
                        <AddMultiSwapButton handleUpdate={handleUpdate}/>
                    </div>
                </div>}

            {whitelistAccount.length > 0 && wallet.publicKey && !whitelistAccount.includes(wallet.publicKey.toString()) &&
                <div className="glass-effect info-panel">
                    <h3>Access Denied</h3>
                    <p>You are not allowed to access this area</p>
                </div>
            }

            {!wallet.publicKey &&
                <div className="glass-effect info-panel">
                    <h3>No Wallet Selected</h3>
                    <p>Kindly login with your wallet</p>
                </div>
            }
        </>
    )
}

export default SwapView;
