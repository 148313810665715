import {FC} from "react";

import SwapAccountItem from "./SwapAccountItem"
import { SwapAccount } from "../../types/bank";
import "./SwapAccountList.css"

interface Props {
    swapStates: SwapAccount[],
    handleUpdate: Function
}

const SwapAccountList: FC<Props> = (props) => {
    const swapStates: SwapAccount[] = props.swapStates.filter(swapState => Object.keys(swapState.account).includes('oldMint') && Object.keys(swapState.account).includes('newMint'))
    return (
        <div className="swap-account-list">
        {swapStates && swapStates.map((swapState) =>
            <SwapAccountItem swapState={swapState} handleUpdate={props.handleUpdate} key={swapState.publicKey.toString()}/>
        )}
        </div>
    )
}

export default SwapAccountList;
