import {FC} from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {AnchorProvider, Program} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";

import { WhitelistAccount } from "../../types/bank";
import "./WhitelistAccountTable.css"
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {PublicKey, SystemProgram, Transaction} from "@solana/web3.js";

interface Props {
    whitelistState: WhitelistAccount[],
    handleUpdate: Function
}

const WhitelistTable: FC<Props> = (props) => {
    const roles = ["Root", "Admin"]

    const { connection } = useConnection();
    const wallet = useWallet();

    const handleRemove = async (account) => {
        if (!wallet) throw new WalletNotConnectedError();
        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [whitelistAccount] = await PublicKey.findProgramAddress(
                [Buffer.from("whitelist"), account.account.authority.toBuffer()],
                program.programId
            );

            const tx = new Transaction().add(
                program.instruction.removeWhitelist({
                    accounts: {
                        user: userAddress,
                        authority: account.account.authority,
                        pdaWhitelist: whitelistAccount,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx);
            await props.handleUpdate();
        } catch (err) {
            console.error({err});
        }
    }

    return (
        <div className="whitelist-table-container glass-effect">
        <table>
            <thead>
            <tr>
                <th>Wallet Address</th>
                <th>Role</th>
                <th>Action</th>
            </tr>
            </thead>

            <tbody>
            {props.whitelistState.map((whitelist) =>
                <tr key={whitelist.account.authority.toString()}>
                    <td>{whitelist.account.authority.toString()}</td>
                    <td>{roles[whitelist.account.role - 1]}</td>
                    <td><button className="small-button" onClick={() => {handleRemove(whitelist)}}>Remove</button></td>
                </tr>
            )}
            </tbody>
        </table>
        </div>
    )
}

export default WhitelistTable;
