import {FC, useCallback, useEffect, useState} from "react";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import type {Wallet} from "@project-serum/anchor";

import Header from "./stake/Header";
import AddWhitelistButton from "./whitelist/AddWhitelistButton";
import WhitelistTable from "./whitelist/WhitelistAccountTable";
import {fetchWhitelistStates} from "../utils/fetch";
import {WhitelistAccount} from "../types/bank";

import "./WhitelistView.css"

const WhitelistView: FC = () => {
    const [whitelistAccount, setWhitelistAccount] = useState<WhitelistAccount[]>([]);
    const [showView, setShowView] = useState<boolean>(false);
    const {connection} = useConnection();
    const wallet = useWallet();

    const getwhitelistAccount = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;

        setShowView(false)

        const whitelistState: WhitelistAccount[] = await fetchWhitelistStates(connection, wallet as any as Wallet);
        setWhitelistAccount(whitelistState);

        const whitelistAdmin = await Promise.all(whitelistState.filter(whitelist => whitelist.account.role === 1))
            .then(async (wlist) => await Promise.all(wlist.map(whitelist => whitelist.account.authority.toString()))
            .then((admins) => {
                setShowView(admins.includes(wallet.publicKey.toString()))
                return admins
            }))
    }, [connection, wallet]);

    async function handleUpdate() {
        await getwhitelistAccount();
    }

    useEffect(() => {
        try {
            getwhitelistAccount().then()
        } catch {
        }
    }, [getwhitelistAccount]);

    return (
        <>
            <Header title="Whitelist Area"/>
            {!wallet.publicKey &&
                <div className="glass-effect info-panel">
                    <h3>No Wallet Selected</h3>
                    <p>Kindly login with your wallet</p>
                </div>
            }
            {wallet.publicKey && showView &&
                <div className="whitelist-account-list">
                    <div>
                        <WhitelistTable whitelistState={whitelistAccount} handleUpdate={handleUpdate}/>
                    </div>
                    <div style={{display: "flex", gap: "8px", justifyContent: "center"}}>
                        <AddWhitelistButton handleUpdate={handleUpdate}/>
                    </div>
                </div>}
            {wallet.publicKey && !showView &&
                <div className="glass-effect info-panel">
                    <h3>Access Denied</h3>
                    <p>You are not allowed to access this area</p>
                </div>
            }
        </>
    )
}

export default WhitelistView;
