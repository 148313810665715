import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {
    PublicKey,
    TransactionInstruction,
    Transaction,
    sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import {
    Metaplex,
    Metadata,
    walletAdapterIdentity,
    findMetadataPda,
    findMasterEditionV2Pda,
} from "@metaplex-foundation/js";
import {FC, useCallback, useRef, useState} from "react";
import {
    createSetAndVerifyCollectionInstruction,
    createUnverifyCollectionInstruction,
} from "@metaplex-foundation/mpl-token-metadata";
import "./AttachCollectionNfts.css";

// import {nftStorage} from "@metaplex-foundation/js-plugin-nft-storage";

interface Props {
    collectionMint: PublicKey;
    name: string;
    toggle: (isPanelVisible: boolean) => void;
}

const UpdateNFTCollectionButton: FC<Props> = ({collectionMint, toggle, name}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {connection} = useConnection();
    const {publicKey, signAllTransactions} = useWallet();
    const [nfts, setNfts] = useState<Metadata[]>([]);
    const [linkedNfts, setLinkedNfts] = useState<string[]>([]);
    const [unlinkedNfts, setUnlinkedNfts] = useState<string[]>([]);
    // const [nftPreviewLimit, setNftPreviewLimit] = useState<number>(5);
    const collectionAttachDiv = useRef<HTMLDivElement>();
    const nftList = useRef<HTMLTextAreaElement>(null);

    const wallet = useWallet()
    const metaplex = Metaplex.make(connection)
        .use(walletAdapterIdentity(wallet))

    const openAttachNfts = () => {
        toggle(true);
        collectionAttachDiv.current.classList.add("visible");
    };

    const closeAttachNfts = () => {
        collectionAttachDiv.current.classList.remove("visible");
        toggle(false);
    };

    const fetchNfts = useCallback(async () => {
        const metaplex = new Metaplex(connection);

        setIsLoading(true);
        try {
            let nftMintSet = new Set()

            nftList.current.value
                .split("\n")
                .map((address) => {
                    if (address.trim() && address.trim().length >= 43 && address.trim.length <= 44) {
                        nftMintSet.add(address.trim())
                    }
                    return true
                });

            let nftMintList = Array.from(nftMintSet)
            nftMintList = nftMintList.map(address => new PublicKey(address))

            if (nftMintList && nftMintList.length > 0) {
                const nftFetched = (await metaplex
                    .nfts()
                    .findAllByMintList({mints: nftMintList})) as Metadata[];

                setNfts(nftFetched as Metadata[]);
                // setNftPreviewLimit(5);
            }
        } catch (err) {
            // console.error({err})
        } finally {
            setIsLoading(false);
        }
    }, [connection, nftList]);

    const checkNFTsLinked = useCallback(async () => {
        const linked_nfts = nfts.filter(nft => nft.collection && nft.collection.verified && nft.collection.address.toString() === collectionMint.toString()).map(nft => nft.mintAddress.toString())
        const unlinked_nfts = nfts.filter(nft => !nft.collection || !nft.collection.verified || nft.collection.address.toString() !== collectionMint.toString()).map(nft => nft.mintAddress.toString())

        setLinkedNfts(linked_nfts)
        setUnlinkedNfts(unlinked_nfts)

    }, [nfts, collectionMint, unlinkedNfts, linkedNfts])

    const attachNfts = useCallback(async () => {
        if (!publicKey || !signAllTransactions) return;

        const instructions: TransactionInstruction[] = [];
        const unlinked_nfts = nfts.filter(nft => unlinkedNfts.includes(nft.mintAddress.toString()))

        for (const nft of unlinked_nfts as Metadata[]) {
            if (!nft) {
                continue;
            }
            const accounts = {
                metadata: findMetadataPda(nft.mintAddress),
                collectionAuthority: publicKey,
                payer: publicKey,
                updateAuthority: publicKey,
                collectionMint: collectionMint,
                collection: findMetadataPda(collectionMint),
                collectionMasterEditionAccount: findMasterEditionV2Pda(collectionMint),
            };

            if (nft.collection && nft.collection.verified) {
                const nftCollection = await metaplex.nfts().findByMint({mintAddress: nft.collection.address})

                const unVerifyCollectionInstruction =
                    createUnverifyCollectionInstruction({
                        ...accounts,
                        collectionAuthority: nftCollection.updateAuthorityAddress,
                        collectionMint: nft.collection.address,
                        collection: findMetadataPda(nft.collection.address),
                        collectionMasterEditionAccount: findMasterEditionV2Pda(
                            nft.collection.address
                        ),
                    });
                instructions.push(unVerifyCollectionInstruction);
            }

            const verifyCollectionInstruction = createSetAndVerifyCollectionInstruction(accounts);
            instructions.push(verifyCollectionInstruction);
        }

        const transactionSize = 10;
        const transactionGroupSize = 5;
        const transactionGroups: Transaction[][] = [[new Transaction()]];
        for (const instruction of instructions) {
            let transactionGroup = transactionGroups[transactionGroups.length - 1];
            if (transactionGroup.length > transactionGroupSize) {
                transactionGroup = [new Transaction()];
                transactionGroups.push(transactionGroup);
            }
            let transaction = transactionGroup[transactionGroup.length - 1];
            if (transaction.instructions.length > transactionSize) {
                transaction = new Transaction();
                transactionGroup.push(transaction);
            }
            transaction.add(instruction);
        }

        setIsLoading(true);
        for (let i = 0; i < transactionGroups.length; i++) {
            const transactionGroup = transactionGroups[i];

            const blockHash = await connection.getLatestBlockhash();

            for (const tx of transactionGroup) {
                tx.recentBlockhash = blockHash.blockhash;
                tx.feePayer = publicKey!;
            }
            const signedTransactions = await signAllTransactions(transactionGroup);
            for (const signedTransaction of signedTransactions) {
                try {
                    await sendAndConfirmRawTransaction(
                        connection,
                        signedTransaction.serialize(),
                        {
                            maxRetries: 5
                        }
                    )
                } catch (error) {
                    // console.error({error});
                }
            }
        }

        setIsLoading(false);
    }, [connection, publicKey, nfts, collectionMint, signAllTransactions, unlinkedNfts, metaplex]);

    const unAttachNfts = useCallback(async () => {
        if (!publicKey || !signAllTransactions) return;

        const instructions: TransactionInstruction[] = [];
        const linked_nfts = nfts.filter(nft => linkedNfts.includes(nft.mintAddress.toString()))

        for (const nft of linked_nfts as Metadata[]) {
            if (!nft) {
                continue;
            }
            if (!nft.collection) {
                continue;
            }
            if (nft.collection?.address.toString() !== collectionMint.toString()) {
                continue;
            }
            const accounts = {
                metadata: findMetadataPda(nft.mintAddress),
                collectionAuthority: publicKey,
                payer: publicKey,
                updateAuthority: publicKey,
                collectionMint: collectionMint,
                collection: findMetadataPda(collectionMint),
                collectionMasterEditionAccount: findMasterEditionV2Pda(collectionMint),
            };

            if (nft.collection) {
                const unVerifyCollectionInstruction =
                    createUnverifyCollectionInstruction({
                        ...accounts,
                        collectionMint: nft.collection.address,
                        collection: findMetadataPda(nft.collection.address),
                        collectionMasterEditionAccount: findMasterEditionV2Pda(
                            nft.collection.address
                        ),
                    });
                instructions.push(unVerifyCollectionInstruction);
            }
        }

        const transactionSize = 10;
        const transactionGroupSize = 5;
        const transactionGroups: Transaction[][] = [[new Transaction()]];
        for (const instruction of instructions) {
            let transactionGroup = transactionGroups[transactionGroups.length - 1];
            if (transactionGroup.length > transactionGroupSize) {
                transactionGroup = [new Transaction()];
                transactionGroups.push(transactionGroup);
            }
            let transaction = transactionGroup[transactionGroup.length - 1];
            if (transaction.instructions.length > transactionSize) {
                transaction = new Transaction();
                transactionGroup.push(transaction);
            }
            transaction.add(instruction);
        }

        setIsLoading(true);
        for (let i = 0; i < transactionGroups.length; i++) {
            const transactionGroup = transactionGroups[i];
            const blockHash = await connection.getLatestBlockhash();
            transactionGroup.forEach((tx) => {
                tx.recentBlockhash = blockHash.blockhash;
                tx.feePayer = publicKey!;
            });
            const signedTransactions = await signAllTransactions(transactionGroup);
            for (const signedTransaction of signedTransactions) {
                try {
                    await sendAndConfirmRawTransaction(
                        connection,
                        signedTransaction.serialize(),
                        {
                            maxRetries: 5
                        }
                    )
                } catch (error) {
                    // console.error({error});
                }
            }
        }

        setIsLoading(false);
    }, [connection, publicKey, nfts, collectionMint, signAllTransactions, linkedNfts]);

    return (
        <div className="collection-attach">
            <div className="collection-attach-options" ref={collectionAttachDiv}>
                <div className="glass-effect collection-attach-panel">
                    <div className="collection-attach-fields">
                        <h3>Attach NFTs to {name} collection</h3>
                        <p>
                            By attaching NFTs to a collection account means updating their collection to the one
                            assigned to the account.
                            You need to be the NFTs update authority to attach NFTs to a collection account of both the
                            NFTs and the collection NFT.
                        </p>

                        <textarea ref={nftList}></textarea>

                        <p className="nft-preview-report">{nfts.length} NFTs found | {linkedNfts.length} Linked NFTs
                            | {unlinkedNfts.length} Unlinked NFTs</p>

                        <div className="nft-list-container">
                            <div>
                                <h4>Linked NFTs</h4>
                                <ul className="nft-list">
                                    {linkedNfts.map(nft =>
                                        <li key={nft}>{nft}</li>
                                    )}
                                </ul>
                            </div>

                            <div>
                                <h4>Unlinked NFTs</h4>
                                <ul className="nft-list">
                                    {unlinkedNfts.map(nft =>
                                        <li key={nft}>{nft}</li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        {/*<div className="nft-preview-grid">*/}
                        {/*  {nfts.slice(0, nftPreviewLimit).map((nft, index) => (*/}
                        {/*    <div className="nft-preview" key={index}>*/}
                        {/*      <a*/}
                        {/*        href={`https://explorer.solana.com/address/${nft.mintAddress}`}*/}
                        {/*        target="_blank"*/}
                        {/*      >*/}
                        {/*        {nft.name}*/}
                        {/*      </a>*/}
                        {/*    </div>*/}
                        {/*  ))}*/}
                        {/*</div>*/}
                        {/*{nfts.length > 0 && (*/}
                        {/*  <button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => {*/}
                        {/*      setNftPreviewLimit(nftPreviewLimit + 5);*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Display more NFTs*/}
                        {/*  </button>*/}
                        {/*)}*/}
                    </div>
                    <div className="nft-action-btn-container">
                        <button type="button" onClick={closeAttachNfts}>
                            Cancel
                        </button>
                        <button type="button" onClick={fetchNfts}>
                            Fetch NFTs
                        </button>
                        <button type="button" onClick={checkNFTsLinked}>
                            Check NFTs
                        </button>
                        <button type="button" onClick={attachNfts}>
                            Attach NFTs
                        </button>
                        <button type="button" onClick={unAttachNfts}>
                            Unttach NFTs
                        </button>
                        {isLoading && <div className="lds-dual-ring"></div>}
                    </div>
                </div>
            </div>
            <button type="button" onClick={openAttachNfts}>
                Manage NFTs
            </button>
        </div>
    );
};

export default UpdateNFTCollectionButton;
