import { createContext, useState, FC, ReactNode, useCallback } from "react";
import { Metadata } from "@metaplex-foundation/js";

export interface NftCache {
  cachedNfts: Record<string, Metadata[]>;
  cacheNfts: (nfts: Metadata[], creatorKey: number) => void;
}

export const NftCacheContext = createContext<Partial<NftCache>>({});

const NftCacheContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [cachedNfts, setCachedNfts] = useState<Record<string, Metadata[]>>({});
  const cacheNfts = useCallback(
    (nfts: Metadata[], key: number) => {
      cachedNfts[key] = nfts;
      setCachedNfts(cachedNfts);
    },
    [cachedNfts]
  );

  return (
    <NftCacheContext.Provider value={{ cachedNfts, cacheNfts }}>
      {children}
    </NftCacheContext.Provider>
  );
};

export default NftCacheContextProvider;
