import {FC, useState, useEffect, useCallback} from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import type { Wallet } from "@project-serum/anchor";
// import {FLOWER_ADDRESSES, REWARD_TOKEN_MINT} from "../../utils/constant";
import { fetchStakeStates, fetchBankStates } from "../../utils/fetch";
import { REWARD_TOKEN_DECIMAL } from "../../utils/constant";
import "./Header.css";

const formatter = Intl.NumberFormat("en", { notation: "compact" });

function useWindowSize() {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(window.innerWidth);
    }

    if (isWindowClient) {
      window.addEventListener("resize", setSize);
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);
  return windowSize;
}

const MOBILE_BREAPOINT = 800;

interface Props {
  title: string;
}

const Header: FC<Props> = ({ title}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const windowSize = useWindowSize();

  const [totalStakedVolume, setTotalStakedVolume] = useState<number>(0);
  const [totalStakedCount, setTotalStakedCount] = useState<[number, number]>([
    0, 0,
  ]);
  const [bankWeekRewards, setBankWeekRewards] = useState<[number, number]>([
    0, 0,
  ]);
  const [bankBalance, setBankBalance] = useState<number>(0);

  const getTotalStakeStats = useCallback(async (): Promise<
    [number, [number, number]]
  > => {
    const stakeStates = await fetchStakeStates(
      connection,
      wallet as any as Wallet
    );

    let totalVolumeCumul = 0;
    let totalStakedCumul = 0;
    stakeStates.forEach((nft) => {
      totalVolumeCumul += nft.account.timestampCumul.toNumber();
      if (nft.account.staked) {
        totalStakedCumul += 1;
        const timestampStart = nft.account.timestampStart.toNumber();
        totalVolumeCumul += (Date.now() - timestampStart * 1000) / 1000;
      }
    });
    return [
      Math.round(
        (totalVolumeCumul / REWARD_TOKEN_DECIMAL / REWARD_TOKEN_DECIMAL) * 100
      ) / 100,
      [totalStakedCumul, stakeStates.length],
    ];
  }, [wallet, connection]);

  const getBankWeekReward = useCallback(async (): Promise<[number, number]> => {
    const bankStates = await fetchBankStates(
      connection,
      wallet as any as Wallet
    );

    // const ogBank =
    //   bankStates.find(
    //     (bank) =>
    //       bank.account.collectionId.toString() ===
    //       FLOWER_ADDRESSES["OG"].toString()
    //   )?.account.rewardMultiplicator || 0;
    // const dBank =
    //   bankStates.find(
    //     (bank) =>
    //       bank.account.collectionId.toString() ===
    //       FLOWER_ADDRESSES["3D"].toString()
    //   )?.account.rewardMultiplicator || 0;
    // return [ogBank, dBank].map(
    //   (r) =>
    //     Math.round((r / REWARD_TOKEN_DECIMAL) * 60 * 60 * 24 * 7 * 100) / 100
    // ) as [number, number];
  }, [wallet, connection]);

  useEffect(() => {
    const interval = setInterval(
      async () => {
        const [totalVolume, totalCount] = await getTotalStakeStats();
        totalCount[1] = 1111;
        setTotalStakedVolume(totalVolume);
        setTotalStakedCount(totalCount);
      },
      totalStakedCount[0] === 0 ? 5 * 1000 : 30 * 1000
    );

    return () => clearInterval(interval);
  }, [totalStakedCount, getTotalStakeStats]);

  useEffect(() => {
    const interval = setInterval(
      async () => {
        const bankWeekReward = await getBankWeekReward();
        setBankWeekRewards(bankWeekReward);
      },
      totalStakedCount[0] === 0 ? 2 * 1000 : 60 * 1000
    );

    return () => clearInterval(interval);
  }, [bankWeekRewards, getBankWeekReward, totalStakedCount]);
  //
  // useEffect(() => {
  //   const fetchTokenBalance = async (
  //       connection: Connection,
  //       wallet: Wallet | PublicKey
  //   ) => {
  //     const associatedToken = await getAssociatedTokenAddress(
  //         REWARD_TOKEN_MINT,
  //         wallet instanceof PublicKey ? wallet : wallet.publicKey,
  //         true
  //     );
  //
  //     try {
  //       const userTokenAccounts = await getAccount(connection, associatedToken);
  //       return Number(userTokenAccounts.amount) / REWARD_TOKEN_DECIMAL;
  //     } catch (e) {
  //       return 0;
  //     }
  //   };
  //
  //   fetchTokenBalance(connection, wallet)
  // }, [connection, wallet]);


  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 flex-row-reverse">
        <WalletMultiButton className="wallet-button" />

        {/*{windowSize > MOBILE_BREAPOINT && (*/}
        {/*  <div className="weekly-reward glass-effect glass-preset-small">*/}
        {/*    Single NFT weekly reward*/}
        {/*    <span className="ps-3 orange-text">OG : 0</span>*/}
        {/*    <span className="ps-3 green-text">3D : 0</span>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <div className="d-flex justify-content-between align-items-end position-relative header-bottom">
        {/*{windowSize > MOBILE_BREAPOINT && (*/}
        {/*  <div className="volume-locked glass-effect glass-preset-small">*/}
        {/*    <div className="title">Total volume locked</div>*/}
        {/*    <div className="value">*/}
        {/*      {formatter.format(totalStakedVolume)}*/}
        {/*      <b className="token-key"> FLWR</b>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="logo-circle"></div>
        <div className="center-title glass-effect glass-preset-small">
          <h2 style={{ margin: 0, textAlign: "center" }}>{title}</h2>
        </div>
        {/*{windowSize > MOBILE_BREAPOINT && (*/}
        {/*  <div className="volume-staked glass-effect glass-preset-small">*/}
        {/*    <div className="title">Total NFT staked</div>*/}
        {/*    <div className="value" style={{textAlign: 'center'}}>*/}
        {/*      {totalStakedCount[0]}*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className="progress"*/}
        {/*      style={*/}
        {/*        {*/}
        {/*          "--progress": `${*/}
        {/*            (totalStakedCount[0] / totalStakedCount[1]) * 100*/}
        {/*          }%`,*/}
        {/*        } as Record<string, string>*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <div className="progress-bar" role="progressbar"></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </>
  );
};

export default Header;
