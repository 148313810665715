import {FC, useCallback, useRef, useState} from "react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {
    PublicKey,
    Transaction,
    SystemProgram,
} from "@solana/web3.js";
import {createTransferCheckedInstruction} from "@solana/spl-token";
import {AnchorProvider, Program, BN} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";

import stakeProgramIdl from "../../idl/flwr_programs.json";
import {getOrCreateAssociatedToken, getOrCreateAssociatedTokens} from "../../utils/swap";
import "./AddSingleSwapButton.css";

interface Props {
    handleUpdate: Function
}

const InitSwapButton: FC <Props> = (props) => {
    const addSwapDiv = useRef<HTMLDivElement>();
    const oldMintField = useRef<HTMLInputElement>();
    const newMintField = useRef<HTMLInputElement>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const {connection} = useConnection();
    const wallet = useWallet();

    const initAssociatedTokens = async (newMint: PublicKey, oldMint: PublicKey, PDA: PublicKey) => {
        const tokenList = [
            {
                mintAddress: oldMint,
                ownerAccount: PDA,
                isPDA: true
            },
            {
                mintAddress: newMint,
                ownerAccount: PDA,
                isPDA: true
            },
            {
                mintAddress: newMint,
                ownerAccount: wallet.publicKey,
                isPDA: false
            },
        ]
        await getOrCreateAssociatedTokens(connection, wallet, tokenList)
    }

    const handleAddSwap = useCallback(
        async () => {
            if (!wallet) throw new WalletNotConnectedError();
            if (!oldMintField.current.value.trim() || !newMintField.current.value.trim()) return;
            if (oldMintField.current.value.trim() === newMintField.current.value.trim()) {
                setErrorMsg("Both token can't be same")
                return
            }

            setIsLoading(true);

            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const oldMint = new PublicKey(oldMintField.current.value);
            const newMint = new PublicKey(newMintField.current.value);

            const [swapAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("swap_bank"), oldMint.toBuffer()],
                program.programId
            );

            // Associated Token Addresses
            await initAssociatedTokens(oldMint, newMint, swapAccount);
            const newMintAtaPda = await getOrCreateAssociatedToken(connection, wallet, newMint, swapAccount, true);
            const newMintAtaHolder = await getOrCreateAssociatedToken(connection, wallet, newMint, wallet.publicKey, false);

            const tx: Transaction = new Transaction().add(
                createTransferCheckedInstruction(
                    newMintAtaHolder, // sender ATA
                    newMint, // mint
                    newMintAtaPda, // receiver ATA
                    wallet.publicKey, // owner
                    1,
                    0,
                )
            ).add(
                program.instruction.initializeSwap(new BN(bump), oldMint, newMint, {
                    accounts: {
                        user: wallet.publicKey as PublicKey,
                        oldMint,
                        pdaSwap: swapAccount,
                        systemProgram: SystemProgram.programId,
                    }
                })
            );

            await provider.sendAndConfirm(tx);
            setIsLoading(false);
            closeAddSwapModal();
            await props.handleUpdate();
        },
        [connection, wallet, newMintField, oldMintField]
    );

    const openAddSwapModal = () => {
        addSwapDiv.current.classList.add("visible");
    };

    const closeAddSwapModal = () => {
        addSwapDiv.current.classList.remove("visible");
        oldMintField.current.value = ""
        newMintField.current.value = ""
        setErrorMsg("")
    };

    return (
        <div className="collection-create">
            <div className="create-collection-options" ref={addSwapDiv}>
                <div className="glass-effect create-collection-panel">
                    <div className="create-collection-fields">
                        <h3>Add NFT Swap</h3>
                        <div className="collection-input">
                            <label>Old NFT Address</label>
                            <input type="text" placeholder="Old Mint" ref={oldMintField}/>
                        </div>
                        <div className="collection-input">
                            <label>New NFT Address</label>
                            <input type="text" placeholder="New Mint" ref={newMintField}/>
                        </div>
                    </div>
                    {errorMsg && <p className="error-text">{errorMsg}</p>}
                    <div className="create-collection-confirm">
                        <button type="button" onClick={closeAddSwapModal}> Cancel</button>
                        <button type="button" onClick={handleAddSwap}> Add</button>
                        {isLoading && <div className="lds-dual-ring"></div>}
                    </div>
                </div>
            </div>
            <button type="button" onClick={openAddSwapModal}>
                Add Single Swap
            </button>
        </div>
    );
};

export default InitSwapButton;
