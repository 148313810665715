import {FC, useCallback, useRef, useState} from "react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {
    PublicKey,
    SystemProgram,
    TransactionInstruction
} from "@solana/web3.js";
import {AnchorProvider, Program, BN} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";

import stakeProgramIdl from "../../idl/flwr_programs.json";
import {
    executeTransactions,
    generateInstructions, getOrCreateAssociatedToken,
    getOrCreateAssociatedTokens
} from "../../utils/swap";
import "./AddSingleSwapButton.css";
import {createTransferCheckedInstruction} from "@solana/spl-token";

interface Props {
    handleUpdate: Function
}

interface BulkData {
    old: string;
    new: string;
}

const InitSwapButton: FC<Props> = (props) => {
    const addSwapDiv = useRef<HTMLDivElement>();
    const swapListField = useRef<HTMLInputElement>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bulkData, setBulkData] = useState<BulkData[]>([])

    const {connection} = useConnection();
    const wallet = useWallet();

    const readBulkData = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(JSON.parse(fileReader.result))
            }

            fileReader.onerror = reject

            fileReader.readAsText(file, 'UTF-8');
        })
    }

    const initAssociatedTokens = async (programId) => {
        const tokenList = []

        for (const data of bulkData) {
            const [swapAccount] = await PublicKey.findProgramAddress(
                [Buffer.from("swap_bank"), data.oldMint.toBuffer()],
                programId
            );

            const tokens = [
                {mintAddress: data.oldMint, ownerAccount: swapAccount, isPDA: true},
                {mintAddress: data.newMint, ownerAccount: swapAccount, isPDA: true},
                {mintAddress: data.newMint, ownerAccount: wallet.publicKey, isPDA: false}
            ]

            tokenList.push(...tokens)
        }

        await getOrCreateAssociatedTokens(connection, wallet, tokenList)
    }

    const handleAddSwap = useCallback(
        async () => {
            if (!wallet) throw new WalletNotConnectedError();
            if (!swapListField.current.files) return;

            setIsLoading(true);

            const data = await readBulkData(swapListField.current.files[0]);

            const bulkData = data.filter(itm => itm.old !== itm.new).map(itm => {
                return {oldMint: new PublicKey(itm.old), newMint: new PublicKey(itm.new)}
            });

            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await initAssociatedTokens(program.programId);

            const instructions: TransactionInstruction[] = [];

            for (const swapItem of bulkData) {
                const [swapAccount, bump] = await PublicKey.findProgramAddress(
                    [Buffer.from("swap_bank"), swapItem.oldMint.toBuffer()],
                    program.programId
                );

                const newMintAtaPda = await getOrCreateAssociatedToken(connection, wallet as any as Wallet, swapItem.newMint, swapAccount, true);
                const newMintAtaHolder = await getOrCreateAssociatedToken(connection, wallet as any as Wallet, swapItem.newMint, wallet.publicKey, false);

                instructions.push(
                    createTransferCheckedInstruction(
                        newMintAtaHolder, // sender ATA
                        swapItem.newMint, // mint
                        newMintAtaPda, // receiver ATA
                        wallet.publicKey, // owner
                        1,
                        0,
                    )
                )

                instructions.push(
                    program.instruction.initializeSwap(new BN(bump), swapItem.oldMint, swapItem.newMint, {
                        accounts: {
                            user: wallet.publicKey as PublicKey,
                            oldMint: swapItem.oldMint,
                            pdaSwap: swapAccount,
                            systemProgram: SystemProgram.programId,
                        }
                    })
                )
            }

            const transactions = generateInstructions(instructions);
            await executeTransactions(connection, wallet, transactions);

            setIsLoading(false);

            closeAddSwapModal();

            await props.handleUpdate();
        },
        [connection, wallet]
    );

    const openAddSwapModal = () => {
        addSwapDiv.current.classList.add("visible");
    };

    const closeAddSwapModal = () => {
        addSwapDiv.current.classList.remove("visible");
    };

    return (
        <div className="collection-create">
            <div className="create-collection-options" ref={addSwapDiv}>
                <div className="glass-effect create-collection-panel">
                    <div className="create-collection-fields">
                        <h3>Add NFT Swap</h3>
                        <div className="collection-input">
                            <label>Swap List (.json)</label>
                            <input type="file" placeholder="Swap List" accept="application/JSON" ref={swapListField}/>
                        </div>
                    </div>
                    <div className="create-collection-confirm">
                        <button type="button" onClick={closeAddSwapModal}> Cancel</button>
                        <button type="button" onClick={handleAddSwap}> Add</button>
                        {isLoading && <div className="lds-dual-ring"></div>}
                    </div>
                </div>
            </div>
            <button type="button" onClick={openAddSwapModal}>
                Add Bulk Swap
            </button>
        </div>
    );
};

export default InitSwapButton;
