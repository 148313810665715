import {FC, useCallback, useEffect, useState} from "react";
import Header from "./stake/Header";
import CreateCollectionButton from "./collection/CreateCollectionButton";
import LinkCollectionButton from "./collection/LinkCollectionButton";
import CollectionAccountList from "./collection/CollectionAccountList";
import NftCacheContextProvider from "../contexts/NftCache";
import {BankAccount} from "../types/bank";
import {fetchOwnedStakingBanks, fetchWhitelistStates} from "../utils/fetch";
import {Wallet} from "@project-serum/anchor";
import {PublicKey, sendAndConfirmRawTransaction, Transaction} from "@solana/web3.js";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {createAssociatedTokenAccountInstruction, getAssociatedTokenAddress} from "@solana/spl-token";
import {REWARD_TOKEN_MINT} from "../utils/constant";
import "./AdminView.css";

const AdminView: FC = () => {
    const {connection} = useConnection();
    const wallet = useWallet();
    const [bankAccount, setBankAccount] = useState<BankAccount[]>([]);
    const [whitelistAccount, setWhitelistAccount] = useState([]);
    const [tokenAccount, setTokenAccount] = useState<PublicKey>();

    const getWhitelistAccounts = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;
        const whitelists = (await fetchWhitelistStates(connection, wallet as any as Wallet)).map(whitelist => whitelist.account.authority.toString())
        setWhitelistAccount(whitelists)
    }, [connection, wallet])

    const getBankAccounts = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;

        const myBankAccounts: BankAccount[] = await fetchOwnedStakingBanks(
            connection,
            wallet as any as Wallet
        );

        setBankAccount(myBankAccounts);

        if (myBankAccounts.length > 0) {
            const associatedTokenAcc = await getAssociatedTokenAddress(
                REWARD_TOKEN_MINT,
                myBankAccounts[0].publicKey,
                true
            );

            const bankAssociatedAcc = await connection.getAccountInfo(associatedTokenAcc)

            if (!bankAssociatedAcc) {
                const tx = new Transaction().add(createAssociatedTokenAccountInstruction(
                        wallet.publicKey,
                        associatedTokenAcc,
                        myBankAccounts[0].publicKey,
                        REWARD_TOKEN_MINT
                    )
                );

                try {
                    const blockHash = await connection.getLatestBlockhash();
                    tx.recentBlockhash = blockHash.blockhash;
                    tx.feePayer = wallet.publicKey;
                    // console.log(await tx.getEstimatedFee(connection))

                    const signedTransaction = await wallet.signTransaction(tx);
                    await sendAndConfirmRawTransaction(
                        connection,
                        signedTransaction.serialize()
                    )

                    // console.log(("::Associated Token Created::").toUpperCase())
                    // console.log(associatedTokenAcc.toBase58())
                    setTokenAccount(associatedTokenAcc)
                } catch (err) {
                    // console.error({err})
                }
            } else {
                setTokenAccount(associatedTokenAcc)
            }

        }
    }, [connection, wallet]);

    useEffect(() => {
        try {
            getWhitelistAccounts()
            getBankAccounts();
        } catch {
        }
    }, [getWhitelistAccounts, getBankAccounts]);

    return (
        <>
            <Header title="The Tool Sheds"/>
            {whitelistAccount.length > 0 && wallet.publicKey && whitelistAccount.includes(wallet.publicKey.toString()) &&
                <div className="admin-view">
                    <NftCacheContextProvider>
                        <div>
                            <CollectionAccountList/>
                            {
                                bankAccount.length === 1 &&
                                <div className="action-button-container">
                                    <CreateCollectionButton/>
                                    <LinkCollectionButton/>
                                </div>
                            }
                        </div>
                    </NftCacheContextProvider>
                </div>
            }
            {whitelistAccount.length > 0 && wallet.publicKey && !whitelistAccount.includes(wallet.publicKey.toString()) &&
            <div className="glass-effect info-panel">
                <h3>Access Denied</h3>
                <p>You are not allowed to access this area</p>
            </div>
            }
            {!wallet.publicKey &&
                <div className="glass-effect info-panel">
                    <h3>No Wallet Selected</h3>
                    <p>Kindly login with your wallet</p>
                </div>
            }
        </>
    );
};

export default AdminView;
