import Nft from "./nft";
import {
    Connection,
    TransactionInstruction,
    Transaction,
    sendAndConfirmRawTransaction,
} from "@solana/web3.js";
import type {Wallet} from "@project-serum/anchor";
import {REWARD_TOKEN_MINT} from "./constant";
import {
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddress,
} from "@solana/spl-token";

// import {useWallet} from "@solana/wallet-adapter-react";

interface NftInstruction {
    nft?: Nft;
    instruction: TransactionInstruction;
}


const generateTransactions = async (
    instructions: NftInstruction[]
) => {
    const transactionSize = 5;
    const transactionGroupSize = 9;
    const transactionGroups: Transaction[][] = [[new Transaction()]];
    for (const instruction of instructions) {
        instruction.nft?.setProcessing();
        let transactionGroup = transactionGroups[transactionGroups.length - 1];
        if (transactionGroup.length >= transactionGroupSize) {
            transactionGroup = [new Transaction()];
            transactionGroups.push(transactionGroup);
        }
        let transaction = transactionGroup[transactionGroup.length - 1];
        if (transaction.instructions.length >= transactionSize) {
            transaction = new Transaction();
            transactionGroup.push(transaction);
        }
        transaction.add(instruction.instruction);
    }
    return transactionGroups
}

const executeNftInstructions = async (
    connection: Connection,
    wallet: Wallet,
    instructions: NftInstruction[],
    transactionGroups: Transaction[][],
    callback: (nft?: Nft) => void = () => {
    }
) => {
    let staggeredTransaction: Promise<string>[] = transactionGroups.map((transactionGroup, idx) => {
        return (new Promise((resolve, reject) => {
            setTimeout(() => {
                connection.getLatestBlockhash().then(async (blockHash) => {
                    for (const tx of transactionGroup) {
                        tx.recentBlockhash = blockHash.blockhash;
                        tx.feePayer = wallet.publicKey;
                    }
                    const signedTransactions = await wallet.signAllTransactions(transactionGroup);

                    for (const signedTransaction of signedTransactions) {
                        try {
                            await sendAndConfirmRawTransaction(
                                connection,
                                signedTransaction.serialize(),
                            );
                            for (let i = 0; i < signedTransaction.instructions.length; i++) {
                                const instruction = instructions.shift();
                                if (!instruction) continue;
                                instruction.nft?.setProcessing(false);
                                callback(instruction.nft);
                            }
                        } catch (error) {
                            // console.error({error});
                        }
                    }
                }).then(resolve).catch(reject)
            }, idx * 2000)
        }))
    })

    return await Promise.allSettled(staggeredTransaction);
}

const getUserTokenAccountInstruction = async (
    connection: Connection,
    wallet: Wallet
) => {
    const instructions: NftInstruction[] = [];

    const userRewardTokenAddress = await getAssociatedTokenAddress(
        REWARD_TOKEN_MINT,
        wallet.publicKey
    );

    const userRewardTokenAccount = await connection.getAccountInfo(
        userRewardTokenAddress
    );

    if (!userRewardTokenAccount) {
        // console.log("::Creating Reward Token Account::");
        instructions.push({
            instruction: createAssociatedTokenAccountInstruction(
                wallet.publicKey,
                userRewardTokenAddress,
                wallet.publicKey,
                REWARD_TOKEN_MINT
            ),
        });
    }
    return instructions;
};

const stakeNft = async (connection: Connection, wallet: Wallet, nft: Nft) => {
    const instructions = await nft.getStakeInstructions(connection, wallet);
    const ix = instructions.map((instruction) => {
            return {nft, instruction};
        })
    const tx = await generateTransactions(ix)

    await executeNftInstructions(
        connection,
        wallet,
        ix,
        tx,
        (nft?: Nft) => {
            nft?.setStaked();
            nft?.setSelected(false);
        }
    );
};

const recursiveStakeSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    }
) => {
    const result = await stakeSelectedNfts(connection, wallet, nfts, callback)
    const rejectedResult = result.filter(res => res.status === 'rejected' && res.reason.error.code !== 4001)
    if (rejectedResult.length > 0) {
        const newNft = nfts.filter(nft => nft.interactiveData.selected)
        await recursiveStakeSelectedNfts(connection, wallet, newNft, callback)
    }
}

const stakeSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    }
) => {
    const instructions: NftInstruction[] = [];

    for (const nft of nfts) {
        if (!nft.interactiveData.selected) continue;
        if (nft.stakeData.staked) continue;
        const nftInstructions = await nft.getStakeInstructions(connection, wallet);
        instructions.push(
            ...nftInstructions.map((instruction) => {
                return {nft, instruction};
            })
        );
    }

    const tx = await generateTransactions(instructions)

    return await executeNftInstructions(
        connection,
        wallet,
        instructions,
        tx,
        (nft?: Nft) => {
            nft?.setStaked();
            nft?.setSelected(false);
            callback(nft);
        }
    );
};

const unstakeNft = async (
    connection: Connection,
    wallet: Wallet,
    nft: Nft,
    noClaim?: boolean
) => {
    const instructions = await nft.getUnstakeInstructions(
        connection,
        wallet,
        noClaim
    );
    const ix = instructions.map((instruction) => {
            return {nft, instruction};
        })
    const tx = await generateTransactions(ix);

    await executeNftInstructions(
        connection,
        wallet,
        ix,
        tx,
        (nft?: Nft) => {
            nft?.setStaked(false);
            nft?.setSelected(false);
        }
    );
};

const recursiveUnstakeSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    },
    noClaim?: boolean
) => {
    const result = await unstakeSelectedNfts(connection, wallet, nfts, callback)
    const rejectedResult = result.filter(res => res.status === 'rejected' && res.reason.error.code !== 4001)
    if (rejectedResult.length > 0) {
        const newNft = nfts.filter(nft => nft.interactiveData.selected)
        await recursiveUnstakeSelectedNfts(connection, wallet, newNft, callback)
    }
}

const unstakeSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    },
    noClaim?: boolean
) => {
    const instructions: NftInstruction[] = [];
    instructions.push(
        ...(await getUserTokenAccountInstruction(connection, wallet))
    );

    for (const nft of nfts) {
        if (!nft.interactiveData.selected) continue;
        if (!nft.stakeData.staked) continue;
        // nft.setSelected(false);
        const nftInstructions = await nft.getUnstakeInstructions(
            connection,
            wallet,
            noClaim
        );
        instructions.push(
            ...nftInstructions.map((instruction) => {
                return {nft, instruction};
            })
        );
    }

    const tx = await generateTransactions(instructions)

    return await executeNftInstructions(
        connection,
        wallet,
        instructions,
        tx,
        (nft?: Nft) => {
            nft?.setStaked(false);
            nft?.setSelected(false);
            callback(nft);
        }
    );
};

const claimNft = async (connection: Connection, wallet: Wallet, nft: Nft) => {
    const instructions: NftInstruction[] = [];
    instructions.push({
        instruction: await nft.getClaimInstruction(connection, wallet),
    });
    instructions.push(
        ...(await getUserTokenAccountInstruction(connection, wallet))
    );
    const tx = await generateTransactions(instructions)
    await executeNftInstructions(connection, wallet, instructions, tx);
};

const recursiveClaimSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    }
) => {
    const result = await claimSelectedNfts(connection, wallet, nfts, callback)
    const rejectedResult = result.filter(res => res.status === 'rejected' && res.reason.error.code !== 4001)
    if (rejectedResult.length > 0) {
        const newNft = nfts.filter(nft => nft.interactiveData.selected)
        await recursiveClaimSelectedNfts(connection, wallet, newNft, callback)
    }
}

const claimSelectedNfts = async (
    connection: Connection,
    wallet: Wallet,
    nfts: Nft[],
    callback: (nft?: Nft) => void = () => {
    }
) => {
    const instructions: NftInstruction[] = [];
    instructions.push(
        ...(await getUserTokenAccountInstruction(connection, wallet))
    );

    const stakedNfts = nfts.filter(nft => nft.stakeData.staked)
    for (const nft of stakedNfts) {
        nft.setSelected(true)
    }

    for (const nft of stakedNfts) {
        if (!nft.interactiveData.selected) continue;
        if (!nft.stakeData.staked) continue;
        const instruction = await nft.getClaimInstruction(connection, wallet);
        instructions.push({
            nft,
            instruction,
        });
    }

    const tx = await generateTransactions(instructions)

    return await executeNftInstructions(
        connection,
        wallet,
        instructions,
        tx,
        (nft?: Nft) => {
            nft?.setSelected(false);
            callback(nft);
        }
    );
};

export {
    stakeNft,
    stakeSelectedNfts,
    recursiveStakeSelectedNfts,
    unstakeNft,
    unstakeSelectedNfts,
    recursiveUnstakeSelectedNfts,
    claimNft,
    claimSelectedNfts,
    recursiveClaimSelectedNfts
};
