import { FC, useState, useEffect, useCallback, useRef } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import { fetchOwnedFlowers, fetchSwapStates, patchNfts } from "../utils/fetch";
import Nft from "../utils/nft";
import Header from "./stake/Header";
import CardStack from "./stake/CardStack";
import type { Wallet } from "@project-serum/anchor";
import { SwapAccount } from "../types/bank";
import "./StakingView.css";
import SwappingPopup from "./swap/SwappingPopup";
import React from "react";

const StakingView: FC = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const mx = Metaplex.make(connection).use(walletAdapterIdentity(wallet))
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [swappingToken, setSwappingToken] = useState<SwapAccount[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const nftFetchingPromise = useRef<Promise<Nft[]>>(Promise.resolve([]));

  const getOwnedNfts = useCallback(async () => {
    setIsFetching(true);
    if (!wallet) throw new WalletNotConnectedError();

    const fetchingPromise = fetchOwnedFlowers(
      connection,
      wallet as any as Wallet
    );
    nftFetchingPromise.current = fetchingPromise;
    setNfts(await fetchingPromise);

    setIsFetching(false);
  }, [wallet, connection]);

  const updateNfts = useCallback(
    (newNfts: Nft[]) => {
      setNfts(patchNfts(nfts, newNfts));
    },
    [nfts]
  );

  const getSwapAccounts = useCallback(async () => {
    const ownerNfts = (await mx.nfts().findAllByOwner({ owner: wallet.publicKey }))
    const nftsList = await Promise.all(ownerNfts.map(nft => nft.mintAddress.toString()));
    const swapStates = (await fetchSwapStates(connection, wallet))

    const swappedList = await Promise.all(swapStates.filter(swap => nftsList.includes(swap.account.oldMint.toString())));
    setSwappingToken(swappedList);
  }, [connection, wallet])

  useEffect(() => {
    if (wallet.connected) {
      getOwnedNfts();
      getSwapAccounts()
    } else {
      nftFetchingPromise.current.then(() => setNfts([]));
    }
  }, [wallet, getOwnedNfts, getSwapAccounts]);

  return (
    <>
      <Header title="The Garden" isAdmin={false} />
      <div className="stacks-wrapper">
        {/* Non stacked Area */}
        <CardStack
          nfts={nfts.filter((nft) => !nft.stakeData.staked)}
          nftTotal={nfts}
          isStake={false}
          updateNfts={updateNfts}
          isFetching={isFetching}
        />

        {/* Stacked Area */}
        <CardStack
          nfts={nfts.filter((nft) => nft.stakeData.staked)}
          nftTotal={nfts}
          isStake={true}
          updateNfts={updateNfts}
          isFetching={isFetching}
        />
      </div>
      {swappingToken.length > 0 && <SwappingPopup swapTokens={swappingToken} />}
    </>
  );
};

export default StakingView;
