import { PROGRAM_ID as METADATA_PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import { JsonMetadata } from "@metaplex-foundation/js";
import {
  Connection,
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import { getStakingProgram } from "./programs";
import stakeProgramIdl from "../idl/flwr_programs.json";
import type { Wallet } from "@project-serum/anchor";
import { BN } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import type { PartialBy } from "./typing";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { REWARD_TOKEN_MINT, REWARD_TOKEN_DECIMAL } from "./constant";

interface NftData {
  tokenAddress: PublicKey;
  mintAddress: PublicKey;
  editionAddress: PublicKey;
  ownerAddress: PublicKey;
  collectionAddress: PublicKey;
  collectionAccount: PublicKey;
  json: JsonMetadata;
  image: string;
}

interface StakeData {
  stakeAddress: PublicKey;
  bankAddress: PublicKey;
  bankOwner: PublicKey;
  bankMultiplier: number;
  bump: number;
  initialized: boolean;
  staked: boolean;
  timestampCumul: number;
  timestampStart: number;
}

interface InteractiveData {
  processing: boolean;
  selected: boolean;
}

export default class NFT {
  nftData: NftData;
  stakeData: StakeData;
  interactiveData: InteractiveData;

  constructor(
    nftData: PartialBy<NftData, "editionAddress" | "image">,
    stakeData: Partial<StakeData>,
    interactiveData: Partial<InteractiveData>,
    wallet: Wallet
  ) {
    const [stakeAddress, stakeBump] = PublicKey.findProgramAddressSync(
      [Buffer.from("stake"), nftData.mintAddress.toBuffer()],
      new PublicKey(stakeProgramIdl.metadata.address)
    );
    const [bankAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("flwr_bank")],
      new PublicKey(stakeProgramIdl.metadata.address)
    );

    // const [collectionAddress] = PublicKey.findProgramAddressSync(
    //     [Buffer.from("collection"), nftData.collectionAddress.toBuffer()],
    //     new PublicKey(stakeProgramIdl.metadata.address)
    // );

    const [editionAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        METADATA_PROGRAM_ID.toBuffer(),
        nftData.mintAddress.toBuffer(),
        Buffer.from("edition"),
      ],
      METADATA_PROGRAM_ID
    );

    this.nftData = {
      editionAddress,
      image: nftData.json.image || "",
      ...nftData,
    };
    this.stakeData = {
      stakeAddress,
      bankAddress,
      bankOwner: bankAddress,
      bankMultiplier: 0,
      bump: stakeBump,
      initialized: false,
      staked: false,
      timestampCumul: 0,
      timestampStart: Date.now(),
      ...stakeData,
    };
    this.interactiveData = {
      selected: false,
      processing: false,
      ...interactiveData,
    };
  }

  getInitializeInstruction(connection: Connection, wallet: Wallet) {
    const stackingProgram = getStakingProgram(connection, wallet);
    return stackingProgram.instruction.initializeStake(
      new BN(this.stakeData.bump),
      {
        accounts: {
          user: wallet.publicKey,
          nftToken: this.nftData.tokenAddress,
          pdaStake: this.stakeData.stakeAddress,
          systemProgram: SystemProgram.programId,
        },
      }
    );
  }

  async getStakeInstructions(connection: Connection, wallet: Wallet) {
    const stackingProgram = getStakingProgram(connection, wallet);
    const instructions: TransactionInstruction[] = [];

    if (!this.stakeData.initialized) {
      instructions.push(this.getInitializeInstruction(connection, wallet));
    }

    instructions.push(
      stackingProgram.instruction.stake({
        accounts: {
          user: wallet.publicKey,
          delegate: wallet.publicKey,
          pdaStake: this.stakeData.stakeAddress,
          nftToken: this.nftData.tokenAddress,
          nftMint: this.nftData.mintAddress,
          nftEdition: this.nftData.editionAddress,
          programMetadata: METADATA_PROGRAM_ID,
          programToken: TOKEN_PROGRAM_ID,
        },
      })
    );
    return null;
  }

  async getUnstakeInstructions(
    connection: Connection,
    wallet: Wallet,
    noClaim?: boolean
  ) {
    const stackingProgram = getStakingProgram(connection, wallet);
    const instructions: TransactionInstruction[] = [];

    // if (!noClaim) {
    //   instructions.push(await this.getClaimInstruction(connection, wallet));
    // }
    instructions.push(
      stackingProgram.instruction.unstake({
        accounts: {
          user: wallet.publicKey,
          delegate: wallet.publicKey,
          pdaStake: this.stakeData.stakeAddress,
          nftToken: this.nftData.tokenAddress,
          nftMint: this.nftData.mintAddress,
          nftEdition: this.nftData.editionAddress,
          programMetadata: METADATA_PROGRAM_ID,
          programToken: TOKEN_PROGRAM_ID,
        },
      })
    );
    return instructions;
  }

  async getClaimInstruction(connection: Connection, wallet: Wallet) {
    // console.log("Staked Data: ", this.stakeData)

    const stackingProgram = getStakingProgram(connection, wallet);
    const bankTokenAddress: PublicKey = await getAssociatedTokenAddress(
      REWARD_TOKEN_MINT,
      this.stakeData.bankAddress,
      true
    );
    const userTokenAddress: PublicKey = await getAssociatedTokenAddress(
      REWARD_TOKEN_MINT,
      wallet.publicKey
    );

    return null;

    // return stackingProgram.instruction.claim({
    //   accounts: {
    //     user: wallet.publicKey,
    //     tokenSource: bankTokenAddress,
    //     tokenDestination: userTokenAddress,
    //     pdaCollection: this.nftData.collectionAccount,
    //     pdaBank: this.stakeData.bankAddress,
    //     pdaStake: this.stakeData.stakeAddress,
    //     programToken: TOKEN_PROGRAM_ID,
    //   },
    // });
  }

  toggleSelection() {
    this.setSelected(!this.interactiveData.selected);
  }

  setSelected(value: boolean = true) {
    this.interactiveData.selected = value;
  }

  setProcessing(value: boolean = true) {
    this.interactiveData.processing = value;
  }

  setStaked(value: boolean = true) {
    this.stakeData.staked = value;
  }

  getRewardEstimation() {
    let cumul = Math.max(this.stakeData.timestampCumul, 0);
    if (this.stakeData.staked) {
      cumul += Math.max(
        (Date.now() - this.stakeData.timestampStart * 1000) / 1000,
        0
      );
    }
    return (cumul * this.stakeData.bankMultiplier) / REWARD_TOKEN_DECIMAL;
  }
}
