import {FC, useState, useEffect, useCallback} from "react"
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {Metaplex, walletAdapterIdentity} from "@metaplex-foundation/js";
import {PublicKey, Transaction, SystemProgram} from "@solana/web3.js"
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {AnchorProvider, Program} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";

import {getOrCreateAssociatedToken} from "../../utils/swap"
import {SwapAccount} from "../../types/bank"
import "./SwapAccountItem.css";

interface Props {
    swapState: SwapAccount,
    handleUpdate: Function
}

const SwapAccountItem: FC<Props> = (props) => {
    const {connection} = useConnection();
    const wallet = useWallet();
    const mx = Metaplex.make(connection).use(walletAdapterIdentity(wallet));

    const [name, setName] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getTokenInfo = useCallback(async () => {
        try {
            const nft = await mx.nfts().findByMint({mintAddress: props.swapState.account.oldMint});
            return { name: nft.name, imageUrl: nft.json.image }
        } catch (err) {
            // console.error({err});
        }
    }, [mx, props.swapState]);

    const removeSwapAccount = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // setIsLoading(true);

        try {
            setIsLoading(true)
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [swapAccount] = await PublicKey.findProgramAddress(
                [Buffer.from("swap_bank"), props.swapState.account.oldMint.toBuffer()],
                program.programId
            );

            let senderAssociateToken: PublicKey;
            let receiverAssociateToken: PublicKey;

            if (props.swapState.account.swapped) {
                senderAssociateToken = await getOrCreateAssociatedToken(connection, wallet, props.swapState.account.oldMint, swapAccount, true)
                receiverAssociateToken = await getOrCreateAssociatedToken(connection, wallet, props.swapState.account.oldMint, wallet.publicKey, false)
            } else {
                senderAssociateToken = await getOrCreateAssociatedToken(connection, wallet, props.swapState.account.newMint, swapAccount, true)
                receiverAssociateToken = await getOrCreateAssociatedToken(connection, wallet, props.swapState.account.newMint, wallet.publicKey, false)
            }

            const tx = new Transaction().add(
                program.instruction.removeSwap({
                    accounts: {
                        user: userAddress,
                        tokenSource: senderAssociateToken,
                        tokenDestination: receiverAssociateToken,
                        oldMint: props.swapState.account.oldMint,
                        pdaSwap: swapAccount,
                        programToken: TOKEN_PROGRAM_ID,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx);
            await props.handleUpdate();
        } catch (err) {
            console.error({err});
        } finally {
            setIsLoading(false);
        }
    }, [wallet, connection, props.swapState.account.oldMint]);

    useEffect(() => {
        getTokenInfo().then(info => {
            setName(info.name)
            setImageUrl(info.imageUrl)
        })
    }, [getTokenInfo])

    return (
        <div className="swap-account-item glass-effect">
            <div className="status" style={{backgroundColor: props.swapState.account.swapped ? '#61ca5f' : '#d5a051'}}></div>
            <div>
                {imageUrl && (
                    <img className="swap-image" src={imageUrl} alt="collection"/>
                )}
            </div>

            <div className="swap-account-data">
                <div className="swap-account-infos">
                    <h5 className="swap-title">{name}</h5>
                    <div className="swap-info-section">
                        <p>Old Token Address</p>
                        <a
                            href={`https://explorer.solana.com/address/${props.swapState.account.oldMint.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.swapState.account.oldMint.toString().slice(0,4)}....{props.swapState.account.oldMint.toString().slice(40,44)}
                        </a>
                    </div>
                    <div className="swap-info-section">
                        <p>New Token Address</p>
                        <a
                            href={`https://explorer.solana.com/address/${props.swapState.account.newMint.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.swapState.account.newMint.toString().slice(0,4)}....{props.swapState.account.newMint.toString().slice(40,44)}
                        </a>
                    </div>
                </div>

                <span className="separation"/>


                <div className="swap-option">
                    <button type="button" onClick={removeSwapAccount}>
                        Remove
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SwapAccountItem;
