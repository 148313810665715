import { Connection, PublicKey } from "@solana/web3.js";
import { AnchorProvider, Program } from "@project-serum/anchor";
import stakeProgramIdl from "../idl/flwr_programs.json";
import type { Idl, Wallet } from "@project-serum/anchor";

const getStakingProgram = (connection: Connection, wallet: Wallet) => {
  const provider = new AnchorProvider(connection, wallet, {
    preflightCommitment: "processed",
    commitment: "processed",
  });
  return new Program(
    stakeProgramIdl as Idl,
    new PublicKey(stakeProgramIdl.metadata.address),
    provider
  );
};

export { getStakingProgram };
