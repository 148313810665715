import {FC, useCallback, createRef, useRef, useState, useEffect} from "react";
import {CollectionAccount} from "../../types/bank";

import {
    Metaplex,
    Nft as MetaplexNft,
    Metadata,
    JsonMetadata,
    walletAdapterIdentity,
    findMetadataPda,
    findMasterEditionV2Pda
} from "@metaplex-foundation/js";
import {PublicKey, sendAndConfirmRawTransaction, SystemProgram, Transaction} from "@solana/web3.js";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {AnchorProvider, BN, Program} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../../idl/flwr_programs.json";
// import AttachCollectionNfts from "./AttachCollectionNfts";
import {
    getAssociatedTokenAddress,
    closeAccount,
    createTransferInstruction,
    createAssociatedTokenAccountInstruction,
    createCloseAccountInstruction,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import { createUnverifyCollectionInstruction } from "@metaplex-foundation/mpl-token-metadata";
import web3 from "@solana/web3.js";
import {REWARD_TOKEN_MINT, REWARD_TOKEN_DECIMAL} from "../../utils/constant";
import AttachCollectionNfts from "./AttachCollectionNfts";
import "./CollectionAccountItem.css";


interface Props {
    collection: CollectionAccount;
}

const CollectionAccountItem: FC<Props> = (props) => {
    const [isCollectionAccount, setIsCollectionAccount] = useState<boolean>(false);
    // const [bankTokenAccount, setBankTokenAccount] = useState<PublicKey>();
    const [collectionName, setCollectionName] = useState<string>();
    const [collectionImageUrl, setCollectionImageUrl] = useState<string>();
    // const [tokenBalance, setTokenBalance] = useState<string>();
    const [panelVisibility, setPanelVisibility] = useState<boolean>(false);
    const authorityWithdrawField = createRef<HTMLInputElement>();
    const rewardMultiplicatorField = useRef<HTMLInputElement>();
    // const traitType = useRef<HTMLInputElement>();
    // const traitValue = useRef<HTMLInputElement>();

    const {connection} = useConnection();
    const wallet = useWallet();

    const updateCollection = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // setIsLoading(true);

        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [collectionAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("collection"), props.collection.account.collectionId.toBuffer()],
                program.programId
            );

            // const traits = [traitType.current.value, traitValue.current.value]
            const reward = rewardMultiplicatorField.current.value

            const tx = new Transaction().add(
                program.instruction.updateCollection(reward, {
                    accounts: {
                        user: userAddress,
                        pdaCollection: props.collection.publicKey,
                        collectionId: props.collection.account.collectionId,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx);
        } catch (err) {
            // console.error({ err });
        } finally {
            // setIsLoading(false);
        }
    }, [wallet, connection]);

    const removeCollectionAcc = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        // setIsLoading(true);

        try {
            const provider = new AnchorProvider(connection, wallet as any as Wallet, {
                preflightCommitment: "processed",
                commitment: "processed",
            });

            const program = new Program(
                stakeProgramIdl as Idl,
                new PublicKey(stakeProgramIdl.metadata.address),
                provider
            );

            await wallet.connect();

            const userAddress = wallet.publicKey as PublicKey;

            const [collectionAccount, bump] = await PublicKey.findProgramAddress(
                [Buffer.from("collection"), props.collection.account.collectionId.toBuffer()],
                program.programId
            );

            const tx = new Transaction().add(
                program.instruction.removeCollection({
                    accounts: {
                        user: userAddress,
                        pdaCollection: props.collection.publicKey,
                        collectionId: props.collection.account.collectionId,
                        systemProgram: SystemProgram.programId,
                    },
                })
            );

            await provider.sendAndConfirm(tx)
        } catch (err) {
            // console.error({ err });
        } finally {
            // setIsLoading(false);
        }
    }, [wallet, connection]);

    useEffect(() => {
        const main = async () => {
            const collectionAssociatedTokenAddress = await getAssociatedTokenAddress(
                REWARD_TOKEN_MINT,
                props.collection.publicKey,
                true
            )

            const  collectionTokenAcc = await connection.getAccountInfo(collectionAssociatedTokenAddress)

            setIsCollectionAccount(!!collectionTokenAcc)
            // setCollectionTokenAccount(collectionAssociatedTokenAddress);

            try {
                const metaplex = new Metaplex(connection);
                const nft = (await metaplex
                    .nfts()
                    .findByMint({mintAddress: props.collection.account.collectionId})) as MetaplexNft;
                let json: JsonMetadata = {};
                json = await (await fetch(nft.uri)).json();
                setCollectionImageUrl(json.image);
                setCollectionName(nft.name);
            } catch {
                return;
            }
        };
        main();
    }, [props.collection, connection, wallet]);

    // useEffect(() => {
    //     // fetchNftByCollection(connection, wallet, props.collection.publicKey
    //     fetchNftsByCreator(connection, wallet, "GynXUqzQE3PiBjVBfxzNt155emYNaX6DT6yQdYegQt8J")
    // }, [props.collection, connection, wallet])
    //

    const togglePanel = (isPanelVisible: boolean) => {
        setPanelVisibility(isPanelVisible);
    };

    return (
        <div
            className="bank-account-item glass-effect"
            style={panelVisibility ? {filter: "none", backdropFilter: "none"} : {}}
        >
            {collectionImageUrl && (
                <img className="bank-image" src={collectionImageUrl} alt="collection"/>
            )}
            <div className="bank-account-data">
                <div className="bank-account-infos">
                    <h5 className="bank-title">{collectionName}</h5>
                    <div className="bank-info-section">
                        <p>Collection Account</p>
                        <a
                            href={`https://explorer.solana.com/address/${props.collection.publicKey.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.collection.publicKey.toString()}
                        </a>
                    </div>
                    <div className="bank-info-section">
                        <p>Collection ID</p>
                        <a
                            href={`https://explorer.solana.com/address/${props.collection.account.collectionId.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.collection.account.collectionId.toString()}
                        </a>
                    </div>
                    <div className="bank-info-section">
                        <p>Owner Account</p>
                        <a
                            href={`https://explorer.solana.com/address/${props.collection.account.authorityWithdraw.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.collection.account.authorityWithdraw.toString()}
                        </a>
                    </div>
                </div>

                <span className="separation"/>

                <div className="bank-update-section">
                    {/*<div className="bank-option">*/}
                    {/*    Trait Type*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        defaultValue={props.collection.account.traits[0]}*/}
                    {/*        ref={traitType}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="bank-option">*/}
                    {/*    Trait Value*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        defaultValue={props.collection.account.traits[1]}*/}
                    {/*        ref={traitValue}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="bank-option">
                        Reward Multiplicator
                        <input
                            type="number"
                            defaultValue={props.collection.account.rewardMultiplier}
                            ref={rewardMultiplicatorField}
                        />
                        <button type="button" onClick={updateCollection}>
                            Update
                        </button>
                    </div>
                </div>

                 <span className="separation"/>


                <div className="bank-option">
                    <AttachCollectionNfts
                        collectionMint={props.collection.account.collectionId}
                        toggle={togglePanel}
                        name={collectionName}
                    />

                    <button type="button" onClick={removeCollectionAcc}>
                        Remove Collection
                    </button>
                </div>

                {/* <div className="bank-refill-section">
                    <div>
                        <p>Token balance</p>
                        <b>{tokenBalance} FLWR</b>
                    </div>
                    <div className="bank-option">
                        Refill amount
                        <input type="number" defaultValue={0} ref={refillAmountField} disabled={!isBankAccount}/>
                    </div>
                    <div className="bank-option">
                        <button type="button" onClick={refillBank} disabled={!isBankAccount}>
                            Refill Bank
                        </button>
                    </div>
                </div> */}

                {/* <span className="separation"/>

                <div className="bank-attach-section">
                    <AttachCollectionNfts
                        collectionMint={props.bank.account.collectionId}
                        toggle={togglePannel}
                        name={collectionName}
                    />
                </div> */}
            </div>
        </div>
    );
};

export default CollectionAccountItem;
