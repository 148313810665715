import {FC, useState, useEffect, useCallback} from "react";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {Metaplex, walletAdapterIdentity} from "@metaplex-foundation/js";

import {SwapAccount} from "../../types/bank";
import "./SwappingPopup.css";
import {PublicKey, SystemProgram, TransactionInstruction} from "@solana/web3.js";
import stakeProgramIdl from "../../idl/flwr_programs.json";
import {
    generateInstructions,
    executeTransactions,
    swapToken, getOrCreateAssociatedTokens,
} from "../../utils/swap";
import {Wallet} from "@project-serum/anchor";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";

interface Props {
    swapTokens: SwapAccount[]
}

const SwappingPopup: FC<Props> = (props) => {
    const {connection} = useConnection();
    const wallet = useWallet();
    const mx = Metaplex.make(connection).use(walletAdapterIdentity(wallet));

    const [metadataList, setMetadataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getMetadata = async () => {
        const mintList = props.swapTokens.map(swap => swap.account.oldMint);
        const tokensMetadata = await Promise.all(mintList.map(mint => mx.nfts().findByMint({mintAddress: mint})));
        setMetadataList(tokensMetadata);
    }

    const initAssociatedTokens = async (programId) => {
        const tokenList = []

        for (const token of props.swapTokens) {
            const [swapAccount] = await PublicKey.findProgramAddress(
                [Buffer.from("swap_bank"), token.account.oldMint.toBuffer()],
                programId
            );

            const tokens = [
                {mintAddress: token.account.oldMint, ownerAccount: swapAccount, isPDA: true},
                {mintAddress: token.account.newMint, ownerAccount: swapAccount, isPDA: true},
                {mintAddress: token.account.newMint, ownerAccount: wallet.publicKey, isPDA: false}
            ]

            tokenList.push(...tokens)
        }

        await getOrCreateAssociatedTokens(connection, wallet as any as Wallet, tokenList)
    }

    const transferToken = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();

        setIsLoading(true);

        await initAssociatedTokens(SystemProgram.programId)

        const instructions: TransactionInstruction[] = [];

        for (const token of props.swapTokens) {
            const [pdaSwap] = PublicKey.findProgramAddressSync(
                [Buffer.from("swap_bank"), token.account.oldMint.toBuffer()],
                new PublicKey(stakeProgramIdl.metadata.address)
            );

            const holder = wallet.publicKey;

            const swapState = {
                oldMint: token.account.oldMint,
                newMint: token.account.newMint,
                swapped: token.account.swapped
            }

            instructions.push(...await swapToken(connection, wallet as any as Wallet, swapState, pdaSwap, holder));
        }

        const transactions = generateInstructions(instructions);

        await executeTransactions(connection, wallet, transactions);

        setIsLoading(false);

        await window.location.reload();
    }, [connection, wallet, props.swapTokens]);

    useEffect(() => {
        getMetadata();
    }, [props.swapTokens])

    return (
        <div className="popup-container">
            <div className="popup glass-effect">
                <div>
                    <h3>You have an update</h3>
                    <p>Some of your NFTs are out-dated and required to update in order to stake</p>
                    <div className="card-array">
                        {metadataList && metadataList.map((metadata) =>
                            <div className="swap-card" key={metadata.address.toString()}>
                                <img src={metadata.json.image} width="200px" height="200px" alt={metadata.name}/>
                                <p>{metadata.name}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <button onClick={transferToken}>Update</button>
                    {isLoading && <div className="lds-dual-ring"></div>}
                </div>
            </div>
        </div>
    )
}

export default SwappingPopup;
