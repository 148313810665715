import React from "react";
import { FC, useState, useEffect } from "react";
import NftCard from "./NftCard";
import Actions from "./Actions";
import Nft from "../../utils/nft";
import { fetchTokenBalance } from "../../utils/fetch";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import type { Wallet } from "@project-serum/anchor";
import { REWARD_TOKEN_DECIMAL } from "../../utils/constant";
import "./CardStack.css";

interface Props {
  nfts: Nft[];
  nftTotal: Nft[];
  updateNfts: (nfts: Nft[]) => void;
  isStake: boolean;
  isFetching: boolean;
}

const formatter = Intl.NumberFormat("en", { notation: "compact" });

const CardStack: FC<Props> = ({
  nfts,
  isStake,
  updateNfts,
  nftTotal,
  isFetching,
}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [walletAddress, setWalletAddress] = useState<string>("no wallet");
  const [tokenBalance, setTokenBalance] = useState<number>();
  const [rewardEstimation, setRewardEstimation] = useState<number>();
  const [rateEstimation, setRateEstimation] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!wallet || !wallet.publicKey) return;
    setWalletAddress(
      wallet?.publicKey?.toString().slice(0, 7) + "..." || "no wallet"
    );
  }, [wallet]);

  useEffect(() => {
    if (!isStake) return;
    const interval = setInterval(
      () => {
        let cumul = 0;
        nfts.forEach((nft) => {
          cumul += nft.getRewardEstimation();
        });
        setRewardEstimation(Math.floor(cumul * REWARD_TOKEN_DECIMAL) / REWARD_TOKEN_DECIMAL);
      },
      rewardEstimation ? 1000 : 100
    );
    return () => clearInterval(interval);
  }, [nfts, rewardEstimation, isStake]);

  useEffect(() => {
    let cumul = 0;
    const daySeconds = 60 * 60 * 24;
    nfts.forEach((nft) => {
      cumul +=
        (nft.stakeData.bankMultiplier / REWARD_TOKEN_DECIMAL) * daySeconds;
    });
    setRateEstimation(Math.round(cumul * REWARD_TOKEN_DECIMAL) / REWARD_TOKEN_DECIMAL);
  }, [nfts]);

  useEffect(() => {
    if (!wallet || !wallet.publicKey || isStake) return;
    const interval = setInterval(
      async () => {
        const balance = await fetchTokenBalance(
          connection,
          wallet as any as Wallet
        );
        setTokenBalance(Math.round(balance * REWARD_TOKEN_DECIMAL) / REWARD_TOKEN_DECIMAL);
      },
      tokenBalance ? 10 * 1000 : 500
    );
    return () => clearInterval(interval);
  }, [connection, wallet, tokenBalance, isStake]);

  return (
    <div className="stake-card glass-effect">
      <Actions
        isStake={isStake}
        nfts={nfts}
        updateNfts={updateNfts}
        nftTotal={nftTotal}
        setLoading={setLoading}
      />
      <div className={`loading-overlay ${loading ? "loading-active" : ""}`}>
        <div className="lds-dual-ring"></div>
      </div>

      <div className="right-column">
        <div className="stake-information">
          <div className="stake-value">
            {isStake ? (
              <>
                <span className="label">Rate/Day</span>
                {formatter.format(rateEstimation)} FLWR
              </>
            ) : (
              <>
                <span className="label">Wallet</span>
                {walletAddress}
              </>
            )}
          </div>
          <div className="stake-value text-purple">
            {isStake ? (
              <>
                <span className="label">To harvest</span>
                {formatter.format(rewardEstimation || 0)} FLWR
              </>
            ) : (
              <>
                <span className="label">Balance</span>
                {formatter.format(tokenBalance || 0)} FLWR
              </>
            )}
          </div>
        </div>

        <div className="nft-grid">
          {nfts.length > 0 ? (
            nfts.map((nft, index) => {
              return <NftCard nft={nft} key={index} updateNfts={updateNfts} />;
            })
          ) : (
            <p className="fallback-text">
              {isFetching ? "" : "No compatible NFTs found"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardStack;
