import {FC, useState, useEffect, useCallback} from "react";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
// import BankAccountItem from "./BankAccountItem";
// import CreateBankButton from "./CreateBankButton";
import type {Wallet} from "@project-serum/anchor";
import {BankAccount, CollectionAccount} from "../../types/bank";
import {
    fetchOwnedStakingBanks,
    fetchCollectionStates,
} from "../../utils/fetch";
// import {Metaplex} from "@metaplex-foundation/js";
import CollectionAccountItem from "./CollectionAccountItem";
import BankAccountItem from "./BankAccountItem";
import "./CollectionAccountList.css";

const CollectionAccountList: FC = () => {
    const {connection} = useConnection();
    const wallet = useWallet();

    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
    const [collectionAccounts, setCollectionAccounts] = useState<CollectionAccount[]>([]);
    // const [totalCollectionAccounts, setTotalCollectionAccounts] = useState<CollectionAccount[]>([]);

    const getBankAccounts = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;

        const myBankAccounts: BankAccount[] = await fetchOwnedStakingBanks(
            connection,
            wallet as any as Wallet
        );

        setBankAccounts(myBankAccounts);
    }, [wallet, connection]);

    const getCollectionAccounts = useCallback(async () => {
        if (!wallet) throw new WalletNotConnectedError();
        if (!wallet.publicKey) return;

        const allCollectionAccounts: CollectionAccount[] = await fetchCollectionStates(connection, wallet as any as Wallet)
        const myCollectionAccounts: CollectionAccount[] = allCollectionAccounts.filter(collection => collection.account.authorityWithdraw.toString() === wallet.publicKey.toString())
        // setTotalCollectionAccounts(allCollectionAccounts);
        setCollectionAccounts(myCollectionAccounts);
    }, [connection, wallet]);

    useEffect(() => {
        try {
            getBankAccounts();
            getCollectionAccounts();
        } catch {
        }
    }, [getBankAccounts, getCollectionAccounts]);

    return (
        <div className="collection-account-list">
            <h3 style={{fontFamily: "Nighty", fontSize: "2rem"}}>Bank Account</h3>
            <BankAccountItem bank={bankAccounts[0]}/>

            {
                bankAccounts.length > 0 &&
                <>
                    <span className="separation"/>
                    <h3 style={{fontFamily: "Nighty", fontSize: "2rem"}}>Collection Accounts</h3>
                    {collectionAccounts.length > 0 ? (
                        collectionAccounts.map((collectionAccount) => (
                            <div
                                key={collectionAccount.publicKey.toString()}
                                style={{width: "100%"}}
                            >
                                <CollectionAccountItem collection={collectionAccount}/>
                            </div>
                        ))
                    ) : (
                        <h4 style={{fontFamily: "Nighty", fontSize: "1.8rem"}}>
                            It looks like this wallet does not own any staking collection
                        </h4>
                    )}
                </>
            }
        </div>
    );
};

export default CollectionAccountList;
