import {FC, useMemo} from "react";
import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui";
import HomeView from "./components/HomeView";

// Default styles that can be overridden by your app
import "@solana/wallet-adapter-react-ui/styles.css";
import "./App.css";

const App: FC = () => {
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(
        () =>
            "https://polished-compatible-sponge.solana-mainnet.quiknode.pro/be90516754a35a05c23f7a4784dab371d5eb9caa/",
        // "https://bold-red-bird.solana-mainnet.quiknode.pro/37568faa0ee51bbef90e87c3cbd55064d80a5519/",
        // "https://api.devnet.solana.com",
        // "http://127.0.0.1:8899",
        [network]
    );

    const wallets = useMemo(
        () => [
            new SolflareWalletAdapter(),
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new TorusWalletAdapter(),
        ],
        []
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletModalProvider>
                    <HomeView/>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;
