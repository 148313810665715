import {
    Metaplex,
    Metadata as MetaplexMetadata,
    JsonMetadata,
    Nft as MetaplexNft, walletAdapterIdentity,
} from "@metaplex-foundation/js";
import {Connection, PublicKey} from "@solana/web3.js";
import type {Wallet} from "@project-serum/anchor";
import {BankAccount, StakeAccount, CollectionAccount, SwapAccount, WhitelistAccount} from "../types/bank";
import {getStakingProgram} from "./programs";
import Nft from "./nft";
import stakeProgramIdl from "../idl/flwr_programs.json";
import {getAccount, getAssociatedTokenAddress} from "@solana/spl-token";
import {REWARD_TOKEN_MINT, REWARD_TOKEN_DECIMAL} from "./constant";
import {Metadata} from "@metaplex-foundation/mpl-token-metadata";
// import {nftStorage} from "@metaplex-foundation/js-plugin-nft-storage";

const fetchOwnedStakingBanks = async (
    connection: Connection,
    wallet: Wallet
) => {
    const stakingProgram = getStakingProgram(connection, wallet);

    return (await stakingProgram.account.bankState.all([
        {
            memcmp: {
                offset: 9,
                bytes: wallet.publicKey.toString(),
            },
        },
    ])) as BankAccount[];
};

const fetchStakeStates = async (connection: Connection, wallet: Wallet) => {
    const stakingProgram = getStakingProgram(connection, wallet);
    return (await stakingProgram.account.stakeState.all()) as StakeAccount[];
};

const fetchBankStates = async (connection: Connection, wallet: Wallet) => {
    const stakingProgram = getStakingProgram(connection, wallet);
    return (await stakingProgram.account.bankState.all()) as BankAccount[];
};

const fetchCollectionStates = async (connection: Connection, wallet: Wallet) => {
    const stakingProgram = getStakingProgram(connection, wallet);
    return (await stakingProgram.account.collectionState.all()) as CollectionAccount[];
};

const fetchSwapStates = async (connection: Connection, wallet: Wallet) => {
    const stakingProgram = getStakingProgram(connection, wallet);
    return (await stakingProgram.account.swapState.all()) as SwapAccount[];
}

const fetchWhitelistStates = async (connection: Connection, wallet: Wallet) => {
    const stakingProgram = getStakingProgram(connection, wallet);
    return (await stakingProgram.account.whitelistState.all()) as WhitelistAccount[];
}

const fetchNft = async (
    connection: Connection,
    wallet: Wallet,
    mint: PublicKey
) => {
    const metaplex = new Metaplex(connection);
    const stakingProgram = getStakingProgram(connection, wallet);

    const nft = (await metaplex
        .nfts()
        .findByMint({mintAddress: mint})) as MetaplexNft;

    const [stakeAddress] = PublicKey.findProgramAddressSync(
        [Buffer.from("stake"), nft.mint.address.toBuffer()],
        new PublicKey(stakeProgramIdl.metadata.address)
    );

    const [bankAddress] = PublicKey.findProgramAddressSync(
        [
            Buffer.from("bank"),
            nft.collection
                ? nft.collection.address.toBuffer()
                : wallet.publicKey.toBuffer(),
        ],
        new PublicKey(stakeProgramIdl.metadata.address)
    );

    let stakingData: StakeAccount["account"] | undefined;
    try {
        stakingData = (await stakingProgram.account.stakeState.fetch(
            stakeAddress
        )) as StakeAccount["account"];
    } catch {
        stakingData = undefined;
    }

    let bankData: BankAccount["account"] | undefined;
    try {
        bankData = (await stakingProgram.account.bankState.fetch(
            bankAddress
        )) as BankAccount["account"];
    } catch {
        bankData = undefined;
    }

    const tokenAddress: PublicKey = await getAssociatedTokenAddress(
        nft.mint.address,
        wallet.publicKey
    );

    return new Nft(
        {
            tokenAddress,
            mintAddress: nft.mint.address,
            ownerAddress: wallet.publicKey,
            collectionAddress: nft.collection?.address || wallet.publicKey,
            json: (await (await fetch(nft.uri)).json()) as JsonMetadata,
        },
        stakingData
            ? {
                initialized: true,
                staked: stakingData.staked,
                timestampCumul: stakingData.timestampCumul.toNumber(),
                timestampStart: stakingData.timestampStart.toNumber(),
                bankOwner: bankData?.authorityWithdraw,
            }
            : {
                bankOwner: bankData?.authorityWithdraw,
            },
        {}
    );
};

const fetchOwnedFlowers = async (connection: Connection, wallet: Wallet) => {
    const metaplex = new Metaplex(connection);
    const stakingProgram = getStakingProgram(connection, wallet);

    const nfts = (await metaplex
        .nfts()
        .findAllByOwner({owner: wallet.publicKey})) as MetaplexMetadata[];

    const stakingDatas = (await stakingProgram.account.stakeState.all([
        {
            memcmp: {
                offset: 26,
                bytes: wallet.publicKey.toString(),
            },
        },
    ])) as StakeAccount[];

    const collectionData = (await stakingProgram.account.collectionState.all())

    // let bankDatas =
    //     (await stakingProgram.account.bankState.all()) as BankAccount[];
    // bankDatas = bankDatas.filter(
    //   (bankData) =>
    //     bankData.account.authorityWithdraw.toString() ==
    //     "22K88i5kiPF2ghwiXQQpzhaH7ZZucdNrQNNNqBYnmGWu"
    // );

    const ownedNfts = await Promise.all(
        nfts.map(async (nft) => {
            const collectionDatum = collectionData.find(
                (s) =>
                    s.account.collectionId.toString() ===
                    nft.collection?.address.toString()
            );
            if (!collectionDatum) {
                return;
            }

            if (nft.collection?.verified !== true) {
                return;
            }

            let json: JsonMetadata = {};
            try {
                json = await (await fetch(nft.uri)).json();
            } catch {
                return;
            }

            const tokenAddress: PublicKey = await getAssociatedTokenAddress(
                nft.mintAddress,
                wallet.publicKey
            );

            let stakingData = stakingDatas.find(
                (s) => s.account.tokenMint.toString() === nft.mintAddress.toString()
            );

            if (!stakingData) {
                const [stakingAddress] = PublicKey.findProgramAddressSync(
                    [Buffer.from("stake"), nft.mintAddress.toBuffer()],
                    new PublicKey(stakeProgramIdl.metadata.address)
                );
                try {
                    stakingData = {
                        account: await stakingProgram.account.stakeState.fetch(
                            stakingAddress
                        ),
                        publicKey: stakingAddress,
                    } as StakeAccount;
                } catch {
                }
            }

            return new Nft(
                {
                    tokenAddress,
                    mintAddress: nft.mintAddress,
                    ownerAddress: wallet.publicKey,
                    collectionAddress: nft.collection?.address,
                    collectionAccount: collectionDatum.publicKey,
                    json,
                },
                stakingData
                    ? {
                        initialized: true,
                        staked: stakingData.account.staked,
                        timestampCumul: stakingData.account.timestampCumul.toNumber(),
                        timestampStart: stakingData.account.timestampStart.toNumber(),
                        bankMultiplier: collectionDatum.account.rewardMultiplier
                    }
                    : {
                        bankMultiplier: collectionDatum.account.rewardMultiplier
                    },
                {},
                wallet
            );
        })
    );

    return ownedNfts.filter((ownedNft) => ownedNft);
};

const patchNfts = (previousNfts: Nft[], newNfts: Nft[]) => {
    const copyNfts = [...previousNfts];
    newNfts.forEach((newNft) => {
        const matchNft = copyNfts.find(
            (n) =>
                n.nftData.mintAddress.toString() ===
                newNft.nftData.mintAddress.toString()
        );
        if (!matchNft) return;
        matchNft.stakeData = newNft.stakeData;
        matchNft.interactiveData = newNft.interactiveData;
    });
    return copyNfts;
};

const fetchTokenBalance = async (
    connection: Connection,
    wallet: Wallet | PublicKey
) => {
    const associatedToken = await getAssociatedTokenAddress(
        REWARD_TOKEN_MINT,
        wallet instanceof PublicKey ? wallet : wallet.publicKey,
        true
    );

    try {
        const userTokenAccounts = await getAccount(connection, associatedToken);
        return Number(userTokenAccounts.amount) / REWARD_TOKEN_DECIMAL;
    } catch (e) {
        return 0;
    }
};

const fetchNftByCollection = async (connection: Connection, wallet: Wallet, collectionId: PublicKey) => {
    let metaplexProgramId = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";

    let allSignatures = [];

    // This returns the first 1000, so we need to loop through until we run out of signatures to get.
    let signatures = await connection.getSignaturesForAddress(collectionId);
    allSignatures.push(...signatures);
    do {
        let options = {
            before: signatures[signatures.length - 1].signature
        };
        signatures = await connection.getSignaturesForAddress(
            collectionId,
            options
        );
        allSignatures.push(...signatures);
    } while (signatures.length > 0);

    let metadataAddresses = [];
    let mintAddresses = new Set<PublicKey>();

    const promises = allSignatures.map((s) =>
        connection.getTransaction(s.signature)
    );
    const transactions = await Promise.all(promises);

    for (const tx of transactions) {
        if (tx) {
            let programIds = tx!.transaction.message
                .programIds()
                .map((p) => p.toString());
            let accountKeys = tx!.transaction.message.accountKeys.map((p) =>
                p.toString()
            );

            // Only look in transactions that call the Metaplex token metadata program
            if (programIds.includes(metaplexProgramId)) {
                // Go through all instructions in a given transaction
                for (const ix of tx!.transaction.message.instructions) {
                    // Filter for setAndVerify or verify instructions in the Metaplex token metadata program
                    if (
                        (ix.data === "K" || ix.data === "S") &&
                        accountKeys[ix.programIdIndex] === metaplexProgramId
                    ) {
                        let metadataAddressIndex = ix.accounts[0];
                        let metadata_address =
                            tx!.transaction.message.accountKeys[metadataAddressIndex];
                        metadataAddresses.push(metadata_address);
                    }
                }
            }
        }
    }

    const promises2 = metadataAddresses.map((a) => connection.getAccountInfo(a));
    const metadataAccounts = await Promise.all(promises2);
    for (const account of metadataAccounts) {
        let metadata = Metadata.deserialize(account!.data);
        mintAddresses.add(metadata[0].mint);
    }
    let mints: PublicKey[] = Array.from(mintAddresses);

    const metaplex = Metaplex.make(connection)
        .use(walletAdapterIdentity(wallet))

    const collectionNfts = (await metaplex.nfts().findAllByMintList({mints})).filter(itm => {
        return itm.collection.address.toString() === collectionId.toString()
    })
}

const fetchNftsByCreator = async (connection: Connection, wallet: Wallet, creatorId: PublicKey) => {
    const metaplex = Metaplex.make(connection)
        .use(walletAdapterIdentity(wallet))

    return (await metaplex.nfts().findAllByCreator({creator: creatorId}))
}

export {
    fetchNft,
    fetchOwnedStakingBanks,
    fetchOwnedFlowers,
    patchNfts,
    fetchTokenBalance,
    fetchStakeStates,
    fetchBankStates,
    fetchCollectionStates,
    fetchSwapStates,
    fetchWhitelistStates,
    fetchNftByCollection,
    fetchNftsByCreator
};
