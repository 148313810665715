import {FC} from "react";
// import agralabLogo from "../assets/flwr_agralabs.png";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import AdminView from "./AdminView";
import StakingView from "./StakingView";
import SwapView from "./SwapView";
import WhitelistView from "./WhitelistView";
import "./HomeView.css";

const HomeView: FC = () => {
    return (
        <div className="page-wrapper">
            <div className="quick-page">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<StakingView/>}/>
                        <Route path="/admin" element={<AdminView/>}/>
                        <Route path="/swap" element={<SwapView/>}/>
                        <Route path="/whitelist" element={<WhitelistView />}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <div className="footer">
                {/*<img src={agralabLogo} className="agralabs-logo" alt="agralab" />*/}
            </div>
        </div>
    );
};

export default HomeView;
