import { FC, useCallback } from "react";
import lock_icon from "../../assets/icons/lock-icon.svg";
import Nft from "../../utils/nft";
import "./NftCard.css";

interface Props {
  nft: Nft;
  updateNfts: (nfts: Nft[]) => void;
}

const NftCard: FC<Props> = ({ nft, updateNfts }) => {
  const toggleSelection = useCallback(() => {
    nft.toggleSelection();
    updateNfts([nft]);
  }, [nft, updateNfts]);

  return (
    <div
      className={`nft-card ${nft.stakeData.staked ? "lock-card" : ""} ${
        nft.interactiveData.selected ? "card-selected" : ""
      }`}
      onClick={toggleSelection}
    >
      <img className="nft-image" src={nft.nftData.image} alt="nft" />
      <div
        className={`card-type ${nft.interactiveData.selected ? "active" : ""}`}
      ></div>

      {!nft.stakeData.staked && (
        <div className="select-box">
          <div className="radio-input">
            <input
              className="radio-input"
              type="checkbox"
              defaultChecked={nft.interactiveData.selected}
            />
            <span className="checkmark"></span>
          </div>
        </div>
      )}

      {nft.stakeData.staked && (
        <img className="lock-icon" src={lock_icon} alt="lock" />
      )}
    </div>
  );
};

export default NftCard;
