import {
    Connection,
    PublicKey,
    SystemProgram,
    Transaction,
    sendAndConfirmRawTransaction, TransactionInstruction
} from "@solana/web3.js";
import {AnchorProvider, Program} from "@project-serum/anchor";
import type {Idl, Wallet} from "@project-serum/anchor";
import stakeProgramIdl from "../idl/flwr_programs.json";
import {
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddress,
    createTransferCheckedInstruction,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";


interface swapState {
    oldMint: PublicKey,
    newMint: PublicKey,
    swapped: boolean
}

interface AssociateTokenState {
    mintAddress: PublicKey,
    ownerAccount: PublicKey,
    isPDA: boolean
}

const generateInstructions = (
    instructions: TransactionInstruction[]
) => {
    const BatchSize = 7;
    const GroupSize = 5
    const transactionsGroup: Transaction[][] = [[new Transaction()]]

    for (const instruction of instructions) {
        let transactionGroup = transactionsGroup[transactionsGroup.length - 1];
        if (transactionGroup.length >= GroupSize) {
            transactionGroup = [new Transaction()];
            transactionsGroup.push(transactionGroup);
        }
        let transaction = transactionGroup[transactionGroup.length - 1];
        if (transaction.instructions.length >= BatchSize) {
            transaction = new Transaction();
            transactionGroup.push(transaction);
        }
        transaction.add(instruction);
    }
    return transactionsGroup
}

const executeTransactions = async (
    connection,
    wallet,
    transactions: Transaction[][]
) => {
    let staggeredTransaction: Promise<string>[] = transactions.map((txGroup, idx) => {
        return (new Promise((resolve, reject) => {
            setTimeout(() => {
                connection.getLatestBlockhash().then(async (blockHash) => {
                    for (const tx of txGroup) {
                        tx.recentBlockhash = blockHash.blockhash;
                        tx.feePayer = wallet.publicKey;
                    }
                    const signedTransactions = await wallet.signAllTransactions(txGroup);

                    for (const signedTransaction of signedTransactions) {
                        try {
                            await sendAndConfirmRawTransaction(
                                connection,
                                signedTransaction.serialize(),
                            );
                        } catch (error) {
                            // console.error({error});
                        }
                    }
                }).then(resolve).catch(reject)
            }, idx * 2000)
        }))
    })

    return await Promise.allSettled(staggeredTransaction);
}

const getOrCreateAssociatedToken = async (connection: Connection, wallet: Wallet, mintAddress: PublicKey, ownerAccount: PublicKey, isPDA: boolean) => {
    const associatedToken = await getAssociatedTokenAddress(mintAddress, ownerAccount, isPDA)

    const associatedTokenAcc = await connection.getAccountInfo(associatedToken)

    if (!associatedTokenAcc) {
        const tx = new Transaction().add(createAssociatedTokenAccountInstruction(
                wallet.publicKey,
                associatedToken,
                ownerAccount,
                mintAddress
            )
        );

        try {
            const blockHash = await connection.getLatestBlockhash();
            tx.recentBlockhash = blockHash.blockhash;
            tx.feePayer = wallet.publicKey;

            const signedTransaction = await wallet.signTransaction(tx);
            await sendAndConfirmRawTransaction(
                connection,
                signedTransaction.serialize()
            )

        } catch (err) {
            console.error({err})
        }
    }

    return associatedToken
}


const getOrCreateAssociatedTokens = async (connection: Connection, wallet: Wallet, tokensList: AssociateTokenState[]) => {
    const instructions: TransactionInstruction[] = [];
    const associatedTokens = []

    for (const {mintAddress, ownerAccount, isPDA} of tokensList) {
        const associatedToken = await getAssociatedTokenAddress(mintAddress, ownerAccount, isPDA)
        associatedTokens.push(associatedToken)
        const associatedTokenAcc = await connection.getAccountInfo(associatedToken)
        if (!associatedTokenAcc) {
            instructions.push(createAssociatedTokenAccountInstruction(
                    wallet.publicKey,
                    associatedToken,
                    ownerAccount,
                    mintAddress
                )
            );
        }
    }

    if (instructions.length > 0) {
        const transactions: Transaction[][] = generateInstructions(instructions);
        await executeTransactions(connection, wallet, transactions);
    }

    return associatedTokens
}


const swapToken = async (connection: Connection, wallet: Wallet, swap_state: swapState, swap_pda: PublicKey, holder: PublicKey) => {
    if (!swap_state.swapped) {
        // get associated token for PDA
        const oldMintAtaPDA = await getOrCreateAssociatedToken(connection, wallet, swap_state.oldMint, swap_pda, true)
        const newMintAtaPDA = await getOrCreateAssociatedToken(connection, wallet, swap_state.newMint, swap_pda, true)

        // get associated token for holder
        const oldMintAtaHolder = await getOrCreateAssociatedToken(connection, wallet, swap_state.oldMint, holder, false)
        const newMintAtaHolder = await getOrCreateAssociatedToken(connection, wallet, swap_state.newMint, holder, false)

        const provider = new AnchorProvider(connection, wallet as any as Wallet, {
            preflightCommitment: "processed",
            commitment: "processed",
        });

        const program = new Program(
            stakeProgramIdl as Idl,
            new PublicKey(stakeProgramIdl.metadata.address),
            provider
        );

        const instructions: TransactionInstruction[] = []

        instructions.push(
            createTransferCheckedInstruction(
                oldMintAtaHolder, // sender ATA
                swap_state.oldMint, // mint
                oldMintAtaPDA, // receiver ATA
                wallet.publicKey, // owner
                1,
                0,
            )
        )

        instructions.push(
            program.instruction.swapToken({
                accounts: {
                    tokenSource: newMintAtaPDA,
                    tokenDestination: newMintAtaHolder,
                    pdaSwap: swap_pda,
                    oldMint: swap_state.oldMint,
                    programToken: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId
                }
            })
        )

        return instructions
    }
}

export {
    generateInstructions,
    executeTransactions,
    getOrCreateAssociatedToken,
    getOrCreateAssociatedTokens,
    swapToken,
}
